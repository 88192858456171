import React, { useState, useEffect } from 'react';
import { Input, Table, Pagination, Row, Col, DatePicker, message, Button, ConfigProvider } from 'antd';
import { ref, get, remove } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';
import moment from 'moment';
import 'moment/locale/pl';
import plPL from 'antd/es/locale/pl_PL';

const { RangePicker } = DatePicker;

moment.updateLocale('pl', {
  week: {
    dow: 1,
    doy: 4,
  },
});

const BonusPartnera = () => {
  // Получаем текущий экземпляр Firebase через контекст
  const { currentFirebase } = useFirebase();
  // Извлекаем базу данных из текущего экземпляра
  const db = currentFirebase.database;

  const [searchValue, setSearchValue] = useState('');
  const [dateRange, setDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [bonusData, setBonusData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    loadAndDisplayData();
  }, []);

  useEffect(() => {
    filterData();
  }, [searchValue, dateRange]);

  const loadAndDisplayData = async () => {
    setLoading(true);
    try {
      const snapshot = await get(ref(db, '/drivers'));
      const drivers = snapshot.val();
      let bonuses = [];

      for (let driverId in drivers) {
        const driverData = drivers[driverId];
        if (driverData.bonus_partnera) {
          for (let bonusId in driverData.bonus_partnera) {
            const bonus = driverData.bonus_partnera[bonusId];

            bonuses.push({
              ...bonus,
              driverId,
              bonusId,
            });
          }
        }
      }

      // Сортировка по дате от самой новой к самой старой
      bonuses.sort((a, b) => moment(b.date).diff(moment(a.date)));

      setBonusData(bonuses);
      setFilteredData(bonuses);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching bonus data: ', error);
      message.error('Błąd podczas pobierania danych.');
    }
    setLoading(false);
  };

  const filterData = () => {
    const lowerCaseSearchValue = searchValue.toLowerCase();

    let filtered = bonusData.filter(bonus => {
      const matchesSearch = Object.keys(bonus).some(key => {
        const value = bonus[key];
        return value && value.toString().toLowerCase().includes(lowerCaseSearchValue);
      });

      const bonusDate = bonus.date; // Дата в формате 'YYYY-MM-DD'

      if (!bonusDate) {
        console.warn(`Invalid date for bonus: ${bonus.bonusId}`);
        return false;
      }

      let matchesDateRange = true;
      if (dateRange && dateRange[0] && dateRange[1]) {
        const startDate = dateRange[0].format('YYYY-MM-DD');
        const endDate = dateRange[1].format('YYYY-MM-DD');

        matchesDateRange = bonusDate >= startDate && bonusDate <= endDate;
      }

      return matchesSearch && matchesDateRange;
    });

    filtered.sort((a, b) => b.date.localeCompare(a.date));

    setFilteredData(filtered);
  };

  const handleDelete = async (bonusId, driverId) => {
    setLoading(true);
    try {
      await remove(ref(db, `/drivers/${driverId}/bonus_partnera/${bonusId}`));
      setBonusData(prevData => prevData.filter(bonus => bonus.bonusId !== bonusId));
      setFilteredData(prevData => prevData.filter(bonus => bonus.bonusId !== bonusId));
      message.success('Bonus został usunięty.');
    } catch (error) {
      console.error('Error deleting bonus data: ', error);
      message.error('Błąd podczas usuwania danych.');
    }
    setLoading(false);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setCurrentPage(1);
  };

  const columns = [
    { title: 'Kierowca', dataIndex: 'driverId', key: 'driverId' },
    { title: 'Kwota', dataIndex: 'amount', key: 'amount' },
    { title: 'Data', dataIndex: 'date', key: 'date' },
    { title: 'Powód', dataIndex: 'reason', key: 'reason' },
    { title: 'Komentarz', dataIndex: 'comment', key: 'comment' },
    {
      title: 'Akcje',
      key: 'action',
      render: (text, record) => (
        <Button danger onClick={() => handleDelete(record.bonusId, record.driverId)}>
          Usuń
        </Button>
      ),
    },
  ];

  const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  return (
    <ConfigProvider locale={plPL}>
      <div>
        <Row gutter={16} style={{ marginBottom: '16px', alignItems: 'center', width: '100%' }}>
          <Col span={24} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
            <Input
              id="search"
              placeholder="Wyszukaj"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ flex: '7', marginRight: '16px' }}
            />
            <RangePicker
              id="date-range"
              style={{ flex: '3' }}
              value={dateRange}
              onChange={(dates) => setDateRange(dates || null)}
              format="YYYY-MM-DD"
            />
          </Col>
        </Row>

        <Table
          id="data-table"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          rowKey="bonusId"
          loading={loading}
        />

        <Pagination
          id="pagination"
          style={{ textAlign: 'right', marginTop: '16px' }}
          current={currentPage}
          total={filteredData.length}
          pageSize={rowsPerPage}
          onChange={handlePageChange}
          pageSizeOptions={['10', '15', '20', '25']}
          onShowSizeChange={handleRowsPerPageChange}
          showSizeChanger
        />
      </div>
    </ConfigProvider>
  );
};

export default BonusPartnera;

import React, { useState, useEffect } from 'react';
import { Input, Table, Pagination, Row, Col, DatePicker, message, Button, ConfigProvider, Spin, Select, Checkbox, Modal } from 'antd';
import { ref, get, remove, update } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';
import moment from 'moment';
import 'moment/locale/pl';
import plPL from 'antd/es/locale/pl_PL';
import { collection, query, where, getDocs, doc, getDoc, updateDoc, setDoc, addDoc } from "firebase/firestore";

const { Option } = Select;
const { RangePicker } = DatePicker;

const Przelewy = () => {
  // Получаем актуальные сервисы из контекста
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;
  const fs = currentFirebase.firestore;
// Используйте:
const { config: firebaseConfig } = currentFirebase;
const firmBankAccountConfig = firebaseConfig.firmBankAccount;

  const [driversData, setDriversData] = useState([]);
  const [selectedDrivers, setSelectedDrivers] = useState([]);
  const [isGenerateModalVisible, setIsGenerateModalVisible] = useState(false);
  const [isGenerateZUSModalVisible, setIsGenerateZUSModalVisible] = useState(false);
  const [isBalancePopupVisible, setIsBalancePopupVisible] = useState(false);
  const [isBankAccountModalVisible, setIsBankAccountModalVisible] = useState(false);
  const [popupDriverId, setPopupDriverId] = useState('');
  const [balanceInput, setBalanceInput] = useState('');
  const [bankAccountInput, setBankAccountInput] = useState('');
  const [balanceAction, setBalanceAction] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(null);
  const [searchText, setSearchText] = useState('');
  const [filteredDriversData, setFilteredDriversData] = useState([]);
  const [isProjectChanging, setIsProjectChanging] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  // Функция обновления таблицы. При каждом изменении базы устанавливаем оверлей, если это не первый рендер
  const updateTable = async () => {
    const startTime = Date.now();
    if (!firstLoad) {
      setIsProjectChanging(true);
    }

    let totalDrivers = 0;
    let totalBankAccounts = 0;
    let totalNormalStatus = 0;
    let totalDuplicateStatus = 0;
    let totalNoAccount = 0;
    let totalBalance = 0;

    const driverRef = ref(db, 'drivers');
    const driverSnapshot = await get(driverRef);

    const driverDataPromises = [];
    driverSnapshot.forEach((driverSnap) => {
      const driverData = driverSnap.val();
      const driverID = driverSnap.key;
      const driverBalance = parseFloat(driverData['balance']).toFixed(2);
      totalDrivers++;
      totalBalance += parseFloat(driverBalance);

      const driverRecord = {
        key: driverID,
        driverID,
        balance: driverBalance,
        uzDriverId: "Brak konta użytkownika",
        bankAccount: "brak",
        accountStatus: "Brak",
      };

      const driverDataPromise = new Promise(async (resolve) => {
        const q = query(collection(fs, 'users'), where('driverId', '==', driverID));
        const querySnapshot = await getDocs(q);
        if (querySnapshot.empty) {
          totalNoAccount++;
          resolve(driverRecord);
        } else if (querySnapshot.size === 1) {
          const userDoc = querySnapshot.docs[0];
          const userID = userDoc.id;
          driverRecord.uzDriverId = userID;

          const danePodatkoweRef = doc(fs, `users/${userID}/dokumenty`, 'dane_podatkowe');
          const danePodatkoweDoc = await getDoc(danePodatkoweRef);
          if (danePodatkoweDoc.exists() && danePodatkoweDoc.data().rachunekBankowy) {
            driverRecord.bankAccount = danePodatkoweDoc.data().rachunekBankowy;
            totalBankAccounts++;
          }

          driverRecord.accountStatus = "Normal";
          totalNormalStatus++;
          resolve(driverRecord);
        } else {
          driverRecord.uzDriverId = "Duplikat konta użytkownika";
          driverRecord.accountStatus = "Duplikat";
          totalDuplicateStatus++;
          resolve(driverRecord);
        }
      });

      driverDataPromises.push(driverDataPromise);
    });

    const resolvedData = await Promise.all(driverDataPromises);
    setDriversData(resolvedData);
    setFilteredDriversData(resolvedData);

    const elapsed = Date.now() - startTime;
    const minimumDisplay = 1000; // минимум 1 секунда
    const delay = Math.max(0, minimumDisplay - elapsed);
    if (!firstLoad) {
      setTimeout(() => {
        setIsProjectChanging(false);
      }, delay);
    } else {
      setFirstLoad(false);
    }
  };

  useEffect(() => {
    updateTable();
  }, [db]);

  useEffect(() => {
    const filtered = driversData.filter((driver) => {
      return (
        driver.driverID.toLowerCase().includes(searchText.toLowerCase()) ||
        driver.balance.toString().includes(searchText) ||
        driver.uzDriverId.toLowerCase().includes(searchText.toLowerCase()) ||
        driver.bankAccount.toLowerCase().includes(searchText.toLowerCase()) ||
        driver.accountStatus.toLowerCase().includes(searchText.toLowerCase())
      );
    });
    setFilteredDriversData(filtered);
  }, [searchText, driversData]);

  const handleSearch = (value) => {
    setSearchText(value);
  };

  const handleSelectDriver = (driverID) => {
    setSelectedDrivers((prevSelected) => {
      if (prevSelected.includes(driverID)) {
        return prevSelected.filter(id => id !== driverID);
      } else {
        return [...prevSelected, driverID];
      }
    });
  };

  const openGenerateModal = () => {
    setIsGenerateModalVisible(true);
  };

  const closeGenerateModal = () => {
    setIsGenerateModalVisible(false);
  };

  const openGenerateZUSModal = () => {
    setIsGenerateZUSModalVisible(true);
  };

  const closeGenerateZUSModal = () => {
    setIsGenerateZUSModalVisible(false);
    setSelectedMonth(null);
  };

  const openBalancePopup = (driverID, action) => {
    setPopupDriverId(driverID);
    setBalanceAction(action);
    setIsBalancePopupVisible(true);
  };

  const closeBalancePopup = () => {
    setIsBalancePopupVisible(false);
    setBalanceInput('');
  };

  const openBankAccountModal = (driverID, bankAccount) => {
    setPopupDriverId(driverID);
    setBankAccountInput(bankAccount);
    setIsBankAccountModalVisible(true);
  };

  const closeBankAccountModal = () => {
    setIsBankAccountModalVisible(false);
    setBankAccountInput('');
  };

  const handleBankAccountSave = async () => {
    if (bankAccountInput.trim()) {
      try {
        const querySnapshot = await getDocs(
          query(collection(fs, 'users'), where('driverId', '==', popupDriverId))
        );
        let userID = '';
  
        if (querySnapshot.size === 1) {
          // Пользователь найден, получаем его ID
          const userDoc = querySnapshot.docs[0];
          userID = userDoc.id;
        } else if (querySnapshot.empty) {
          // Пользователь не найден, создаем нового
          const newUserDocRef = await addDoc(collection(fs, 'users'), { driverId: popupDriverId });
          userID = newUserDocRef.id;
        } else {
          message.error('Multiple user accounts found.');
          return;
        }
  
        const danePodatkoweRef = doc(fs, `users/${userID}/dokumenty`, 'dane_podatkowe');
        console.log(`Updating bank account for userID: ${userID}, path: users/${userID}/dokumenty/dane_podatkowe`);
  
        await setDoc(danePodatkoweRef, { rachunekBankowy: bankAccountInput }, { merge: true });
        message.success('Bank account updated successfully.');
        closeBankAccountModal();
        updateTable();
      } catch (error) {
        message.error("Failed to update bank account: " + error.message);
      }
    } else {
      message.error("Please enter a valid bank account.");
    }
  };
  
  const handleBalanceSave = async () => {
    if (balanceInput && !isNaN(parseFloat(balanceInput))) {
      try {
        const driverRef = ref(db, `drivers/${popupDriverId}`);
        const snapshot = await get(driverRef);
        const currentBalance = parseFloat(snapshot.val().balance || 0);
        const newBalance = Number((currentBalance + parseFloat(balanceInput) * (balanceAction === 'withdraw' ? -1 : 1)).toFixed(2));
        await update(driverRef, { balance: newBalance });        
        message.success(`Balance ${balanceAction === 'withdraw' ? 'withdrawn' : 'deposited'} successfully.`);
        setBalanceInput('');
        closeBalancePopup();
        updateTable();
      } catch (error) {
        message.error("Failed to update balance: " + error.message);
      }
    } else {
      message.error("Please enter a valid amount.");
    }
  };

  const handleGenerateFile = async () => {
    let today = new Date();
    let paymentDate = ('0' + today.getDate()).slice(-2) + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + today.getFullYear();
    let fileContent = '4120414|1\r\n';
    let noBankAccountUsers = [];

    for (let driverID of selectedDrivers) {
      const driver = driversData.find(d => d.driverID === driverID);
      if (!driver) continue;

      const { bankAccount, balance } = driver;

      if (bankAccount !== 'brak') {
        fileContent += `1|${firmBankAccountConfig}|${bankAccount}|${driverID}|brak|${balance}|1|Rozliczenie|${paymentDate}|\r\n`;

        const driverRef = ref(db, `drivers/${driverID}`);
        await update(driverRef, { balance: 0, balanceUmowaNajmu: 0 })
          .catch(error => {
            console.error('Error updating balance:', error);
          });
      } else {
        noBankAccountUsers.push(driverID);
      }
    }

    let blob = new Blob([fileContent], { type: 'text/plain' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'payments.txt';
    link.click();

    if (noBankAccountUsers.length > 0) {
      alert(`Could not process the following users due to missing bank accounts: ${noBankAccountUsers.join(', ')}`);
    }

    message.success('Plik został wygenerowany pomyślnie!');
    closeGenerateModal();
  };

  const handleGenerateZUSFile = async () => {
    if (!selectedMonth) {
      message.error('Please select a month.');
      return;
    }

    const month = moment(selectedMonth).format('MM');
    const year = moment(selectedMonth).format('YYYY');
    const paymentPeriod = `${month}-${year}`;

    const today = new Date();
    const paymentDate = moment(today).format('DD-MM-YYYY');

    let fileContent = '4120414|1\r\n';
    const noBankAccountUsers = [];
    const staticAmount = 220.29.toFixed(2);

    for (let driverID of selectedDrivers) {
      const driver = driversData.find(d => d.driverID === driverID);
      if (!driver) continue;

      const { bankAccount } = driver;

      if (bankAccount !== 'brak') {
        fileContent += `1|${firmBankAccountConfig}|${bankAccount}|${driverID}|brak|${staticAmount}|1|Wyplata zgodnie z Umowa Zlecenia za okres ${paymentPeriod}|${paymentDate}|\r\n`;

        const driverRef = ref(db, `drivers/${driverID}`);
        const snapshot = await get(driverRef);
        const currentBalance = parseFloat(snapshot.val().balance || 0);
        const currentBalanceUmowaNajmu = parseFloat(snapshot.val().balanceUmowaNajmu || 0);

        const newBalance = (currentBalance - 220.29).toFixed(2);
        const newBalanceUmowaNajmu = (currentBalanceUmowaNajmu - 281.00).toFixed(2);

        await update(driverRef, {
          balance: newBalance,
          balanceUmowaNajmu: newBalanceUmowaNajmu
        }).catch(error => {
          console.error('Error updating balance:', error);
        });
      } else {
        noBankAccountUsers.push(driverID);
      }
    }

    let blob = new Blob([fileContent], { type: 'text/plain' });
    let link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'wyplata_zus.txt';
    link.click();

    if (noBankAccountUsers.length > 0) {
      alert(`Could not process the following users due to missing bank accounts: ${noBankAccountUsers.join(', ')}`);
    }

    message.success('Plik ZUS został wygenerowany pomyślnie!');
    closeGenerateZUSModal();
  };

  const columns = [
    {
      title: '',
      dataIndex: 'select',
      key: 'select',
      render: (_, record) => (
        <Checkbox
          checked={selectedDrivers.includes(record.driverID)}
          onChange={() => handleSelectDriver(record.driverID)}
          disabled={parseFloat(record.balance) <= 0}
        />
      )
    },
    { 
      title: 'KR Driver ID', 
      dataIndex: 'driverID', 
      key: 'driverID', 
      sorter: (a, b) => a.driverID.localeCompare(b.driverID) 
    },
    { 
      title: 'Saldo', 
      dataIndex: 'balance', 
      key: 'balance', 
      sorter: (a, b) => parseFloat(a.balance) - parseFloat(b.balance) 
    },
    { title: 'UZ Driver ID', dataIndex: 'uzDriverId', key: 'uzDriverId' },
    {
      title: 'Rachunek Bankowy',
      dataIndex: 'bankAccount',
      key: 'bankAccount',
      render: (text, record) => (
        <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => openBankAccountModal(record.driverID, record.bankAccount)}>
          {text}
        </span>
      ),
    },
    { title: 'Status konta', dataIndex: 'accountStatus', key: 'accountStatus' },
    {
      title: 'Wpłata gotówki',
      key: 'deposit',
      render: (_, record) => (
        <Button 
          className="sign" 
          onClick={() => openBalancePopup(record.driverID, 'deposit')}
          disabled={parseFloat(record.balance) >= 0}
        >
          Wpłata gotówki
        </Button>
      ),
    },
    {
      title: 'Wypłata gotówki',
      key: 'withdraw',
      render: (_, record) => (
        <Button 
          className="signNon" 
          onClick={() => openBalancePopup(record.driverID, 'withdraw')}
          disabled={parseFloat(record.balance) < 0}
        >
          Wypłata gotówki
        </Button>
      ),
    },
  ];

  return (
    <div>
      <Row gutter={16} style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
        <Input.Search
          placeholder="Szukaj"
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
          style={{ width: '80%' }}
        />
        <div>
          <Button
            type="primary"
            disabled={selectedDrivers.length === 0}
            onClick={openGenerateModal}
            style={{ marginLeft: '10px' }}
          >
            Generuj
          </Button>
          <Button
            type="primary"
            disabled={selectedDrivers.length === 0}
            onClick={openGenerateZUSModal}
            style={{ marginLeft: '10px' }}
          >
            Generuj ZUS
          </Button>
        </div>
      </Row>

      <Table columns={columns} dataSource={filteredDriversData} rowKey="driverID" />

      <div style={{ marginTop: '16px' }}>
        Łączna liczba kierowców: {filteredDriversData.length}
      </div>

      <Modal
        title="Generowanie pliku"
        visible={isGenerateModalVisible}
        onCancel={closeGenerateModal}
        footer={[
          <Button key="cancel" onClick={closeGenerateModal}>
            Anuluj
          </Button>,
          <Button key="submit" type="primary" onClick={handleGenerateFile}>
            Generuj
          </Button>,
        ]}
      >
        <Table
          dataSource={selectedDrivers.map(driverID => driversData.find(driver => driver.driverID === driverID))}
          columns={[
            { title: 'KR Driver ID', dataIndex: 'driverID', key: 'driverID' },
            { title: 'Rachunek Bankowy', dataIndex: 'bankAccount', key: 'bankAccount' },
            { title: 'Saldo', dataIndex: 'balance', key: 'balance' },
          ]}
          rowKey="driverID"
          pagination={false}
        />
      </Modal>

      <Modal
        title="Wybierz miesiąc dla ZUS"
        visible={isGenerateZUSModalVisible}
        onCancel={closeGenerateZUSModal}
        footer={[
          <Button key="cancel" onClick={closeGenerateZUSModal}>
            Anuluj
          </Button>,
          <Button key="submit" type="primary" onClick={handleGenerateZUSFile}>
            Generuj ZUS
          </Button>,
        ]}
      >
        <DatePicker
          picker="month"
          format="MM-YYYY"
          value={selectedMonth}
          onChange={(date) => setSelectedMonth(date)}
          style={{ width: '100%', marginBottom: '16px' }}
        />
        <Table
          dataSource={selectedDrivers.map(driverID => driversData.find(driver => driver.driverID === driverID))}
          columns={[
            { title: 'KR Driver ID', dataIndex: 'driverID', key: 'driverID' },
            { title: 'Rachunek Bankowy', dataIndex: 'bankAccount', key: 'bankAccount' },
            { title: 'Saldo', dataIndex: 'balance', key: 'balance' },
          ]}
          rowKey="driverID"
          pagination={false}
        />
      </Modal>

      <Modal
        title={`Zmiana salda dla ${popupDriverId}`}
        visible={isBalancePopupVisible}
        onCancel={closeBalancePopup}
        footer={[
          <Button key="cancel" onClick={closeBalancePopup} className="signNon">
            Anuluj
          </Button>,
          <Button key="submit" type="primary" onClick={handleBalanceSave} className="sign">
            Zapisz
          </Button>,
        ]}
      >
        <Input
          placeholder={`Wpisz kwotę do ${balanceAction === 'withdraw' ? 'wypłaty' : 'wpłaty'}`}
          value={balanceInput}
          onChange={(e) => setBalanceInput(e.target.value)}
        />
      </Modal>

      <Modal
        title={`Zmień rachunek bankowy dla ${popupDriverId}`}
        visible={isBankAccountModalVisible}
        onCancel={closeBankAccountModal}
        onOk={handleBankAccountSave}
      >
        <Input
          placeholder="Wpisz nowy numer rachunku bankowego"
          value={bankAccountInput}
          onChange={(e) => setBankAccountInput(e.target.value)}
        />
      </Modal>

      {/* Оверлей для плавного отображения при переключении проекта */}
      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          transition: 'opacity 0.5s ease',
        }}>
          <Spin size="large" />
          <div style={{ marginTop: 16, fontSize: 18, color: '#333' }}>
            Przełączam projekt...
          </div>
        </div>
      )}
    </div>
  );
};

export default Przelewy;

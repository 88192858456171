import React, { useState, useEffect } from 'react';
import { Form, Input, Button, Select, message } from 'antd';
import { collection, doc, setDoc, getDocs } from 'firebase/firestore';
import { useFirebase } from '../FirebaseContext'; // Импортируем хук из контекста

const { TextArea } = Input;
const { Option } = Select;

const AdminNotificationSender = () => {
  const [form] = Form.useForm();
  const [users, setUsers] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [isSending, setIsSending] = useState(false);

  // Получаем текущий экземпляр Firebase из контекста
  const { currentFirebase } = useFirebase();
  // Извлекаем Firestore из текущего экземпляра
  const db = currentFirebase.firestore;

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const usersSnapshot = await getDocs(collection(db, 'users'));
        const usersList = usersSnapshot.docs.map(doc => {
          const data = doc.data();
          return { 
            id: doc.id, 
            firstName: data.firstName || 'Brak imienia', 
            lastName: data.lastName || 'Brak nazwiska', 
            email: data.email || 'Brak emaila' 
          };
        });
        setUsers(usersList);
      } catch (error) {
        console.error("Error fetching users: ", error);
        message.error("Błąd podczas ładowania użytkowników.");
      }
    };

    fetchUsers();
  }, [db]);

  const onFinish = async (values) => {
    setIsSending(true);

    try {
      const promises = selectedUsers.map(async (userId) => {
        const notificationRef = doc(collection(db, `users/${userId}/notifications`));
        await setDoc(notificationRef, {
          title: values.title,
          text: values.text,
          createdAt: new Date(),
          isRead: false
        });
      });

      await Promise.all(promises);
      message.success('Powiadomienia zostały pomyślnie wysłane!');
      form.resetFields();
    } catch (error) {
      message.error('Błąd podczas wysyłania powiadomień.');
    } finally {
      setIsSending(false);
    }
  };

  const onUserSelectChange = (value) => {
    if (value.includes('all')) {
      setSelectedUsers(users.map(user => user.id)); // Выбираем всех пользователей
    } else {
      setSelectedUsers(value);
    }
  };

  return (
    <div>
      <h2>Wyślij powiadomienie do użytkowników</h2>
      <Form form={form} layout="vertical" onFinish={onFinish}>
        <Form.Item
          name="users"
          label="Wybierz użytkowników"
          rules={[{ required: true, message: 'Proszę wybrać przynajmniej jednego użytkownika' }]}
        >
          <Select
            mode="multiple"
            placeholder="Wybierz użytkowników"
            onChange={onUserSelectChange}
            optionLabelProp="label"
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            value={selectedUsers}
          >
            <Option key="all" value="all" label="Wybierz wszystkich">
              Wybierz wszystkich
            </Option>
            {users.map(user => (
              <Option key={user.id} value={user.id} label={`${user.firstName} ${user.lastName}`}>
                {`${user.firstName} ${user.lastName} (${user.email})`}
              </Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="title"
          label="Tytuł"
          rules={[{ required: true, message: 'Proszę wprowadzić tytuł' }]}
        >
          <Input placeholder="Wprowadź tytuł" />
        </Form.Item>

        <Form.Item
          name="text"
          label="Krótki tekst"
          rules={[{ required: true, message: 'Proszę wprowadzić tekst powiadomienia' }]}
        >
          <TextArea rows={4} placeholder="Wprowadź tekst powiadomienia" />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit" loading={isSending}>
            Wyślij powiadomienia
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AdminNotificationSender;

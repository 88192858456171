// firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Конфигурация первого проекта
const firebaseConfig1 = {
  apiKey: "AIzaSyDVz0ho0nQb7Sh9o_0wFLZfVJM7aeGYgb0",
  authDomain: "ccmcolorpartner.firebaseapp.com",
  databaseURL: "https://ccmcolorpartner-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "ccmcolorpartner",
  storageBucket: "ccmcolorpartner.appspot.com",
  messagingSenderId: "417966588632",
  appId: "1:417966588632:web:bf85b1ff7307bb86de8dad",
  measurementId: "G-WQP6RE6CQ3",
  firmBankAccount: "61109020080000000156542155" // Добавляем номер банковского счёта для первого проекта
};

// Конфигурация второго проекта (пример)
const firebaseConfig2 = {
  apiKey: "AIzaSyDrGpIpneoRCIrFtEhyiCUDfLM-Pvl02mY",
  authDomain: "spario-2d6d2.firebaseapp.com",
  databaseURL: "https://spario-2d6d2-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "spario-2d6d2",
  storageBucket: "spario-2d6d2.firebasestorage.app",
  messagingSenderId: "770240014851",
  appId: "1:770240014851:web:679af4c08d93ec4eb2fc36",
  measurementId: "G-MGHYGL0HFL",
  firmBankAccount: "16109020080000000160076481" // Добавляем номер банковского счёта для второго проекта
};

// Инициализация двух приложений с именами
const app1 = initializeApp(firebaseConfig1, "ColorPartner");
const app2 = initializeApp(firebaseConfig2, "Spario");

// Экспорт объекта с данными по обоим проектам
export const firebaseApps = {
  ColorPartner: {
    app: app1,
    auth: getAuth(app1),
    database: getDatabase(app1),
    firestore: getFirestore(app1),
    storage: getStorage(app1),
    config: firebaseConfig1,
  },
  Spario: {
    app: app2,
    auth: getAuth(app2),
    database: getDatabase(app2),
    firestore: getFirestore(app2),
    storage: getStorage(app2),
    config: firebaseConfig2,
  }
};

// Для обратной совместимости экспортируем данные из первого проекта
export const auth = firebaseApps.ColorPartner.auth;
export const firestore = firebaseApps.ColorPartner.firestore;
export const database = firebaseApps.ColorPartner.database;
export const storage = firebaseApps.ColorPartner.storage;

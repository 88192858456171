import React, { useEffect, useState } from 'react';
import { Button, Typography, Modal, Collapse, Card } from 'antd';
import { useFirebase } from '../FirebaseContext'; // Используем контекст для доступа к актуальному Firebase
import { doc, getDoc, collection, getDocs } from 'firebase/firestore';
import moment from 'moment';

const { Title } = Typography;

const UserProfile = ({ userId }) => {
  const { currentFirebase } = useFirebase();
  const firestoreInstance = currentFirebase.firestore; // Получаем актуальный экземпляр Firestore

  const [userData, setUserData] = useState({});
  const [documentsData, setDocumentsData] = useState({});
  const [umowyData, setUmowyData] = useState([]);
  const [isEditing, setIsEditing] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState('');
  const [currentDocumentName, setCurrentDocumentName] = useState('');

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        // Загрузка данных из корневого документа пользователя
        const userDocRef = doc(firestoreInstance, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserData(userDocSnap.data());
        } else {
          console.error('No such document!');
        }

        // Загрузка данных из коллекции dokumenty
        const dokumentyCollectionRef = collection(firestoreInstance, `users/${userId}/dokumenty`);
        const dokumentySnapshot = await getDocs(dokumentyCollectionRef);

        const documents = {};
        dokumentySnapshot.forEach(docSnap => {
          documents[docSnap.id] = docSnap.data();
        });
        setDocumentsData(documents);

        // Загрузка данных из коллекции umowy
        const umowyCollectionRef = collection(firestoreInstance, `users/${userId}/umowy`);
        const umowySnapshot = await getDocs(umowyCollectionRef);

        const umowy = [];
        umowySnapshot.forEach(docSnap => {
          umowy.push({ id: docSnap.id, ...docSnap.data() });
        });
        setUmowyData(umowy);

      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, [userId, firestoreInstance]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleSave = async () => {
    // Добавьте сюда логику сохранения, если необходимо
    setIsEditing(false);
  };

  const handleChange = (field, value) => {
    setUserData(prevState => ({
      ...prevState,
      [field]: value
    }));
  };

  const showModal = (imageUrl, documentName = 'document') => {
    setCurrentImage(imageUrl);
    setCurrentDocumentName(documentName);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setCurrentImage('');
  };

  const downloadImage = async () => {
    try {
      // Добавляем опцию mode: 'cors', если нужно
      const imageResponse = await fetch(currentImage, { mode: 'cors' });
      const imageBlob = await imageResponse.blob();
      const imageURL = URL.createObjectURL(imageBlob);

      const driverName = `${userData.firstName || 'Unknown'}_${userData.lastName || 'Driver'}`;
      const fileName = `${driverName}_${currentDocumentName}.jpg`;

      const link = document.createElement('a');
      link.href = imageURL;
      link.download = fileName;
      document.body.appendChild(link);

      // Создаём и отправляем событие клика
      link.dispatchEvent(new MouseEvent('click', {
        bubbles: true,
        cancelable: true,
        view: window
      }));

      document.body.removeChild(link);
      // Удаляем URL через короткое время, чтобы освободить память
      setTimeout(() => URL.revokeObjectURL(imageURL), 100);
    } catch (error) {
      console.error('Error downloading image:', error);
    }
  };

  // Подготовка элементов для Collapse
  const collapseItems = [
    {
      key: '1',
      label: 'Podstawowe informacje',
      children: (
        <>
          <p><strong>Imię:</strong> {userData.firstName || 'Brak danych'}</p>
          <p><strong>Nazwisko:</strong> {userData.lastName || 'Brak danych'}</p>
          <p><strong>Email:</strong> {userData.email || 'Brak danych'}</p>
          <p><strong>Telefon:</strong> {userData.phoneNumber || 'Brak danych'}</p>
          <p><strong>Rola:</strong> {userData.role || 'Brak danych'}</p>
          <p><strong>Status:</strong> {userData.status || 'Brak danych'}</p>
          <p><strong>Obywatelstwo:</strong> {userData.citizenship || 'Brak danych'}</p>
          <p><strong>Driver ID:</strong> {userData.driverId || 'Brak danych'}</p>
          <p><strong>Okres rozliczenia:</strong> {userData.okresrozliczeniowy || 'Brak danych'}</p>
        </>
      ),
    },
    {
      key: '2',
      label: 'Dane osobowe',
      children: documentsData.dane_personalne ? (
        <>
          <p><strong>Miasto:</strong> {documentsData.dane_personalne.city || 'Brak danych'}</p>
          <p><strong>Data urodzenia:</strong> {documentsData.dane_personalne.dob || 'Brak danych'}</p>
          <p><strong>PESEL:</strong> {documentsData.dane_personalne.pesel || 'Brak danych'}</p>
          <p><strong>Kod pocztowy:</strong> {documentsData.dane_personalne.postalCode || 'Brak danych'}</p>
          <p><strong>Adres korespondencyjny:</strong> {documentsData.dane_personalne.correspondenceAddress || 'Brak danych'}</p>
          <p><strong>Adres zameldowania:</strong> {documentsData.dane_personalne.registrationAddress || 'Brak danych'}</p>
          <p><strong>Adres zamieszkania:</strong> {documentsData.dane_personalne.residenceAddress || 'Brak danych'}</p>
          <p><strong>Ulica:</strong> {documentsData.dane_personalne.street || 'Brak danych'}</p>
        </>
      ) : 'Brak danych',
    },
    {
      key: '3',
      label: 'Dane podatkowe',
      children: documentsData.dane_podatkowe ? (
        <>
          <p><strong>Rachunek bankowy:</strong> {documentsData.dane_podatkowe.rachunekBankowy || 'Brak danych'}</p>
          <p><strong>Urząd skarbowy:</strong> {documentsData.dane_podatkowe.urzadSkarbowy || 'Brak danych'}</p>
          <p><strong>Okres rozliczeniowy:</strong> {documentsData.dane_podatkowe.okresrozliczeniowy || 'Brak danych'}</p>
        </>
      ) : 'Brak danych',
    },
    {
      key: '4',
      label: 'Dane dodatkowe',
      children: documentsData.dane_dodatkowe ? (
        <>
          <p><strong>Badania lekarskie:</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.badaniaLekarskie, 'Badania lekarskie')}>Zobacz</Button></p>
          <p><strong>Badania psychologiczne:</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.badaniaPsychologiczne, 'Badania psychologiczne')}>Zobacz</Button></p>
          <p><strong>Dowód osobisty (przód):</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.dowodOsobistyPrzod, 'Dowód osobisty przód')}>Zobacz</Button></p>
          <p><strong>Dowód osobisty (tył):</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.dowodOsobistyTyl, 'Dowód osobisty tył')}>Zobacz</Button></p>
          <p><strong>Zaświadczenie o niekaralności:</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.niekaralnosc, 'Zaświadczenie o niekaralności')}>Zobacz</Button></p>
          <p><strong>Zdjęcie profilowe:</strong> <Button onClick={() => showModal(documentsData.dane_dodatkowe.zdjecieProfilowe, 'Zdjęcie profilowe')}>Zobacz</Button></p>
        </>
      ) : 'Brak danych',
    },
    {
      key: '5',
      label: 'Dane samochodu',
      children: documentsData.samochod ? (
        <>
          <p><strong>Własność samochodu:</strong> {documentsData.samochod.carOwnership || 'Brak danych'}</p>

          {documentsData.samochod.carOwnership === 'Własny' ? (
            <>
              <p><strong>Marka samochodu:</strong> {documentsData.samochod.carMake || 'Brak danych'}</p>
              <p><strong>Model samochodu:</strong> {documentsData.samochod.carModel || 'Brak danych'}</p>
              <p><strong>Numer rejestracyjny:</strong> {documentsData.samochod.carRegistrationNumber || 'Brak danych'}</p>
              <p><strong>VIN:</strong> {documentsData.samochod.carVin || 'Brak danych'}</p>
              <p><strong>Rok produkcji:</strong> {documentsData.samochod.carYear || 'Brak danych'}</p>
              <p><strong>Dowód Rejestracyjny przód:</strong> <Button onClick={() => showModal(documentsData.samochod.dowodPrzod, 'Dowód Rejestracyjny przód')}>Zobacz</Button></p>
              <p><strong>Dowód Rejestracyjny tył:</strong> <Button onClick={() => showModal(documentsData.samochod.dowodTyl, 'Dowód Rejestracyjny tył')}>Zobacz</Button></p>
              <p><strong>Ubezpieczenie:</strong> <Button onClick={() => showModal(documentsData.samochod.ubezpieczenie, 'Ubezpieczenie')}>Zobacz</Button></p>
            </>
          ) : (
            <p><strong>Informacje o samochodzie są niedostępne dla wynajmowanego auta</strong></p>
          )}
        </>
      ) : 'Brak danych',
    },
    {
      key: '6',
      label: 'Umowy',
      children: umowyData.length > 0 ? (
        umowyData.map((umowa) => (
          <Card key={umowa.id} title={`Umowa ${umowa.id}`} style={{ marginBottom: '16px' }}>
            <p>
              <strong>Data Generacji:</strong>{' '}
              {umowa.dataGeneracji
                ? moment(umowa.dataGeneracji.toDate()).format('DD-MM-YYYY')
                : 'Brak danych'}
            </p>
            <p>
              <strong>Data Ważności:</strong>{' '}
              {umowa.dataWaznosci
                ? moment(umowa.dataWaznosci.toDate()).format('DD-MM-YYYY')
                : 'Brak danych'}
            </p>
            <p>
              <strong>Status:</strong> {umowa.status || 'Brak danych'}
            </p>
            <p>
              <strong>Podpisano:</strong> {umowa.signed ? 'Tak' : 'Nie'}
            </p>
            {umowa.signed && (
              <p>
                <strong>Data Podpisu:</strong>{' '}
                {umowa.signedAt
                  ? moment(umowa.signedAt.toDate()).format('DD-MM-YYYY HH:mm')
                  : 'Brak danych'}
              </p>
            )}
            <p>
              <strong>Podpis:</strong>{' '}
              <Button onClick={() => showModal(umowa.signature, 'Podpis')}>Zobacz</Button>
            </p>
            <p>
              <strong>Dokument PDF:</strong>{' '}
              <Button href={umowa.pdfUrl} target="_blank">
                Otwórz
              </Button>
            </p>

          </Card>
        ))
      ) : 'Brak umów',
    },
  ];

  return (
    <div>
      <Title level={2}>Profil użytkownika</Title>
      <Button onClick={isEditing ? handleSave : handleEdit}>
        {isEditing ? "Zapisz" : "Edytuj"}
      </Button>

      <Collapse
        items={collapseItems}
        defaultActiveKey={['1']}
      />

      <Modal
        visible={modalVisible}
        onCancel={handleModalClose}
        footer={null}
        centered
      >
        <img src={currentImage} alt="Dokument" style={{ width: '100%' }} />
        <Button style={{ marginTop: '16px' }} onClick={downloadImage}>Pobierz jako JPG</Button>
      </Modal>
    </div>
  );
};

export default UserProfile;

import React, { useState, useEffect } from 'react';
import { Card, Modal, Input, Button, message, Form, DatePicker, Select, List, Spin } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, SettingOutlined, LoadingOutlined } from '@ant-design/icons';
import { ref, onValue, update, push, set } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';
import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
dayjs.extend(weekOfYear);

const { Option } = Select;
const { RangePicker } = DatePicker;

const Raport = () => {
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;
  
  // Состояния для конфигурации аккаунтов
  const [configurations, setConfigurations] = useState({});
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedService, setSelectedService] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');

  // Состояния для автоматизации
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [taskForm] = Form.useForm();
  const [tasks, setTasks] = useState([]);

  // Подписка на изменения конфигураций в базе
  useEffect(() => {
    const configRef = ref(db, 'accountConfigurations');
    const unsubscribe = onValue(
      configRef,
      (snapshot) => {
        if (snapshot.exists()) {
          setConfigurations(snapshot.val());
        } else {
          setConfigurations({});
        }
      },
      (error) => {
        console.error("Błąd ładowania konfiguracji:", error);
        message.error('Błąd ładowania konfiguracji');
      }
    );
    return () => unsubscribe();
  }, [db]);

  // Подписка на задания автоматизации
  useEffect(() => {
    const tasksRef = ref(db, 'automationTasks');
    const unsubscribe = onValue(
      tasksRef,
      (snapshot) => {
        const tasksList = [];
        snapshot.forEach((child) => {
          tasksList.push({ id: child.key, ...child.val() });
        });
        setTasks(tasksList);
      },
      (error) => {
        console.error("Błąd ładowania zadań automatyzacji:", error);
        message.error('Błąd ładowania zadań automatyzacji');
      }
    );
    return () => unsubscribe();
  }, [db]);

  // Проверка: подключено ли приложение (есть login и hasło)
  const isConnected = (service) => {
    const serviceData = configurations[service];
    return serviceData && serviceData.login && serviceData.password;
  };

  // Обработка клика по сервису для редактирования данных аккаунта
  const handleServiceClick = (service) => {
    setSelectedService(service);
    const serviceData = configurations[service] || { login: '', password: '' };
    setLogin(serviceData.login);
    setPassword(serviceData.password);
    setEditMode(false);
    setModalVisible(true);
  };

  // Переключение в режим редактирования аккаунта
  const toggleEditMode = () => {
    setEditMode(true);
  };

  // Сохранение изменений в аккаунте
  const handleSave = () => {
    if (!selectedService) return;
    if (!login || !password) {
      message.error('Wprowadź login i hasło');
      return;
    }
    update(ref(db, 'accountConfigurations'), {
      [selectedService]: { login, password }
    })
      .then(() => {
        message.success('Dane zostały zaktualizowane');
        setEditMode(false);
        setModalVisible(false);
      })
      .catch((error) => {
        console.error('Błąd aktualizacji danych:', error);
        message.error('Błąd aktualizacji danych');
      });
  };

  // Открытие модального окна для создания задания автоматизации
  const openTaskModal = () => {
    setTaskModalVisible(true);
    taskForm.resetFields();
  };

  // Обработка отправки формы для создания задания
  const handleTaskSubmit = (values) => {
    const { application, dateRange } = values;
    // Если выбранное приложение не подключено – нельзя создавать задание
    if (!isConnected(application)) {
      message.error('Nie można utworzyć zadania - aplikacja nie jest podłączona');
      return;
    }
    // Определяем дату начала и окончания
    const startDate = dateRange[0];
    const endDate = dateRange[1];
    // Вычисляем номер недели на основе даты начала
    const weekNumber = startDate.week();
    const year = startDate.year();
    const computedWeek = `${weekNumber}-${year}`;

    const tasksRef = ref(db, 'automationTasks');
    const newTaskRef = push(tasksRef);
    const newTask = {
      application,
      startDate: startDate.format('YYYY-MM-DD'),
      endDate: endDate.format('YYYY-MM-DD'),
      week: computedWeek,
      status: 'Oczekuje',
      createdAt: Date.now()
    };
    set(newTaskRef, newTask)
      .then(() => {
        message.success('Zadanie automatyzacji zostało utworzone');
        setTaskModalVisible(false);
      })
      .catch((error) => {
        console.error("Błąd tworzenia zadania:", error);
        message.error('Błąd tworzenia zadania automatyzacji');
      });
  };

  return (
    <div>
      {/* Раздел конфигурации аккаунтов */}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 16 }}>
        <h1>Status połączenia kont</h1>
        <div>
          {['uber', 'bolt', 'freenow'].map((service) => (
            <span
              key={service}
              style={{ margin: '0 8px', cursor: 'pointer', textTransform: 'capitalize', fontSize: 16 }}
              onClick={() => handleServiceClick(service)}
            >
              {service} {isConnected(service) ? <CheckCircleOutlined style={{ color: 'green', fontSize: 20 }} /> : <CloseCircleOutlined style={{ color: 'red', fontSize: 20 }} />}
            </span>
          ))}
        </div>
      </div>

      {/* Раздел автоматизации с кнопкой в заголовке */}
      <Card
        title="Lista zadań automatyzacji"
        extra={<Button type="primary" onClick={openTaskModal}>Utwórz zadanie</Button>}
        style={{ marginBottom: 20 }}
      >
        {tasks.length === 0 ? (
          <p>Brak zadań</p>
        ) : (
          <List
            dataSource={tasks}
            renderItem={(item) => (
              <List.Item
                actions={[
                  item.status === 'Zakończono' && item.storageUrl ? (
                    <Button
                      type="primary"
                      onClick={() => window.open(item.storageUrl, '_blank')}
                    >
                      Pobierz raport
                    </Button>
                  ) : (
                    <Spin indicator={<LoadingOutlined style={{ fontSize: 16 }} spin />} />
                  )
                ]}
              >
                <List.Item.Meta
                  title={`Aplikacja: ${item.application} | ${item.startDate} - ${item.endDate} (tydzień: ${item.week})`}
                  description={`Status: ${item.status}`}
                />
              </List.Item>
            )}
          />
        )}
      </Card>

      {/* Модальное окно для редактирования данных аккаунта */}
      <Modal
        title={
          selectedService
            ? selectedService.charAt(0).toUpperCase() + selectedService.slice(1)
            : ''
        }
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={
          editMode
            ? [
                <Button key="cancel" onClick={() => setModalVisible(false)}>
                  Anuluj
                </Button>,
                <Button key="save" type="primary" onClick={handleSave}>
                  Zapisz
                </Button>
              ]
            : null
        }
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3>Dane konta</h3>
          {!editMode && (
            <SettingOutlined onClick={toggleEditMode} style={{ cursor: 'pointer', fontSize: 20 }} />
          )}
        </div>
        {editMode ? (
          <>
            <div style={{ marginBottom: 16 }}>
              <label>Login:</label>
              <Input value={login} onChange={(e) => setLogin(e.target.value)} />
            </div>
            <div style={{ marginBottom: 16 }}>
              <label>Hasło:</label>
              <Input.Password value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
          </>
        ) : (
          <>
            <div style={{ marginBottom: 16 }}>
              <strong>Login:</strong> {login || 'Nie podano'}
            </div>
            <div style={{ marginBottom: 16 }}>
              <strong>Hasło:</strong> {password ? '********' : 'Nie podano'}
            </div>
          </>
        )}
      </Modal>

      {/* Модальное окно для создания задания автоматyzacji */}
      <Modal
        title="Nowe zadanie automatyzacji"
        visible={taskModalVisible}
        onCancel={() => setTaskModalVisible(false)}
        footer={null}
      >
        <Form form={taskForm} layout="vertical" onFinish={handleTaskSubmit}>
          <Form.Item
            name="application"
            label="Aplikacja"
            rules={[{ required: true, message: 'Wybierz aplikację' }]}
          >
            <Select placeholder="Wybierz aplikację">
              {['uber', 'bolt', 'freenow'].map((service) => (
                <Option key={service} value={service} disabled={!isConnected(service)}>
                  {service} {!isConnected(service) ? '(Nie podłączona)' : ''}
                </Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="dateRange"
            label="Zakres dat"
            rules={[{ required: true, message: 'Wybierz zakres dat' }]}
          >
            <RangePicker format="YYYY-MM-DD" style={{ width: '100%' }} />
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Utwórz zadanie
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default Raport;

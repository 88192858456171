// FirebaseContext.js
import React, { createContext, useContext, useState } from "react";
import { firebaseApps } from "./firebase";
import { notification } from "antd";

const FirebaseContext = createContext();

export const FirebaseProvider = ({ children }) => {
  // Инициализируем состояние, получая значение из localStorage (если оно есть), иначе "ColorPartner"
  const [currentProject, setCurrentProject] = useState(() => {
    return localStorage.getItem("selectedProject") || "ColorPartner";
  });

  const switchProject = (projectKey) => {
    if (firebaseApps[projectKey]) {
      setCurrentProject(projectKey);
      localStorage.setItem("selectedProject", projectKey); // Сохраняем выбранный проект
      notification.success({
        message: `Przełączono do ${projectKey}`,
        placement: "bottomRight",
      });
    } else {
      console.error("Неверный ключ проекта:", projectKey);
    }
  };

  return (
    <FirebaseContext.Provider
      value={{
        currentFirebase: firebaseApps[currentProject],
        currentProject,
        switchProject,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  );
};

export const useFirebase = () => useContext(FirebaseContext);

import React, { useState, useEffect, useMemo, useRef } from 'react';
import {
  Input,
  Button,
  Upload,
  message as antdMessage,
} from 'antd';
import {
  UploadOutlined,
  CloseCircleOutlined,
  FileOutlined,
} from '@ant-design/icons';
import './chat.css';
import { ref as dbRef, push, update, set } from 'firebase/database';
import { getStorage, ref as storageRef, uploadBytes, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import useChatMessages from '../../hooks/useChatMessages';
import { useFirebase } from '../../FirebaseContext';
import CloseChatModal from './CloseChatModal';

const chatWindowStyle = {
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
  width: '100%',
  padding: '10px',
  backgroundColor: '#f5f5f5',
};

const headerStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px',
  backgroundColor: '#fff',
  borderBottom: '1px solid #e8e8e8',
};

const messagesStyle = {
  flex: 1,
  overflowY: 'auto',
  padding: '10px',
};

const inputContainerStyle = {
  padding: '10px',
  borderTop: '1px solid #e8e8e8',
};

const timeAndStatusStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  fontSize: '0.75rem',
  marginTop: '5px',
  color: '#999',
};

const getMessageContainerStyle = (isOwnMessage) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: isOwnMessage ? 'flex-end' : 'flex-start',
  marginBottom: '10px',
});

const getBubbleStyle = (isOwnMessage) => ({
  background: isOwnMessage ? '#d4f0fd' : '#fff',
  padding: '10px',
  borderRadius: '10px',
  maxWidth: '70%',
  boxShadow: '0 1px 3px rgba(0,0,0,0.1)',
});

function truncateFileName(fileName, maxLength = 20) {
  if (fileName.length <= maxLength) return fileName;
  const extIndex = fileName.lastIndexOf('.');
  const extension = extIndex > -1 ? fileName.slice(extIndex) : '';
  const baseName = extIndex > -1 ? fileName.slice(0, extIndex) : fileName;
  return `${baseName.slice(0, maxLength - extension.length - 3)}...${extension}`;
}

function extractFileName(url) {
  try {
    const fullPath = decodeURIComponent(url.split('?')[0]);
    return fullPath.substring(fullPath.lastIndexOf('/') + 1);
  } catch {
    return 'File';
  }
}

function formatDateSeparator(date) {
  const now = new Date();
  const d = new Date(date);
  const isToday =
    d.getDate() === now.getDate() &&
    d.getMonth() === now.getMonth() &&
    d.getFullYear() === now.getFullYear();
  return isToday
    ? 'Dzisiaj'
    : d.toLocaleDateString('pl-PL', {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
      });
}

const ChatWindow = ({ selectedChat, user, userInfo }) => {
  const [message, setMessage] = useState('');
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImageUrl, setPreviewImageUrl] = useState(null);
  const [isCloseModalVisible, setIsCloseModalVisible] = useState(false);
  const [closeReason, setCloseReason] = useState('');

  const openCloseModal = () => {
    setIsCloseModalVisible(true);
  };

  const onCloseModal = () => {
    setIsCloseModalVisible(false);
  };

  const onCloseChat = async () => {
    try {
      const closedAt = new Date().toISOString();
      // Обновляем статус чата в базе данных (Realtime Database)
      await update(dbRef(db, `chats/${selectedChat.id}`), {
        status: 'closed',
        closeReason: closeReason,
        closedAt: closedAt,
      });
      antdMessage.success('Czat został zamknięty.');
    } catch (err) {
      antdMessage.error('Nie udało się zamknąć czatu.');
    } finally {
      setIsCloseModalVisible(false);
    }
  };

  useEffect(() => {
    console.log('Selected chat:', selectedChat);
  }, [selectedChat]);
  
  const messages = useChatMessages(selectedChat?.id);
  useEffect(() => {
    console.log('Messages:', messages);
  }, [messages]);
  
  const messagesEndRef = useRef(null);

  // Получаем сервисы из контекста
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;
  const storage = currentFirebase.storage;
  const firestore = currentFirebase.firestore;

  // Отслеживаем изменения currentFirebase для уведомления о смене проекта
  const prevFirebase = useRef(currentFirebase);
  useEffect(() => {
    if (prevFirebase.current !== currentFirebase) {
      antdMessage.info('Przełączam projekt...', 2);
      prevFirebase.current = currentFirebase;
    }
  }, [currentFirebase]);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  // Отметка непрочитанных сообщений как прочитанных
  useEffect(() => {
    if (!selectedChat || !messages || !messages.length) return;
    
    const updatePaths = {};
    messages.forEach((msg) => {
      if (!msg.isRead && msg.senderRole === 'user') {
        updatePaths[`chats/${selectedChat.id}/messages/${msg.id}/isRead`] = true;
        updatePaths[`chats/${selectedChat.id}/messages/${msg.id}/readAt`] = new Date().toISOString();
      }
    });
    if (
      selectedChat.lastMessage?.senderRole === 'user' &&
      selectedChat.lastMessage?.isRead === false
    ) {
      updatePaths[`chats/${selectedChat.id}/lastMessage/isRead`] = true;
      updatePaths[`chats/${selectedChat.id}/lastMessage/readAt`] = new Date().toISOString();
    }
    if (Object.keys(updatePaths).length > 0) {
      update(dbRef(db), updatePaths);
    }
  }, [selectedChat, messages, db]);

  const sortedMessages = useMemo(() => {
    if (!messages) return [];
    return [...messages].sort((a, b) => new Date(a.timestamp) - new Date(b.timestamp));
  }, [messages]);

  const formattedMessages = useMemo(() => {
    if (!selectedChat) return null;
    let result = [];
    let lastDate = null;
    let lastSenderId = null;
    sortedMessages.forEach((msg, index) => {
      const isOwnMessage = msg.senderRole === 'admin';
      const senderName = isOwnMessage ? 'Ty' : msg.sender;
      const msgDateObj = new Date(msg.timestamp);
      const msgDateString = msgDateObj.toDateString();
      if (msgDateString !== lastDate) {
        result.push(
          <div
            key={`date-${msg.timestamp}`}
            style={{ textAlign: 'center', margin: '12px 0', color: '#888', fontWeight: 500 }}
          >
            {formatDateSeparator(msg.timestamp)}
          </div>
        );
        lastDate = msgDateString;
        lastSenderId = null;
      }
      const showSenderName = (msg.senderId !== lastSenderId) && !isOwnMessage;
      result.push(
        <div key={`${msg.timestamp}-${index}`} style={getMessageContainerStyle(isOwnMessage)}>
          {showSenderName && (
            <div style={{ fontSize: '0.85rem', fontWeight: 500, marginBottom: '4px' }}>
              {senderName}
            </div>
          )}
          <div style={getBubbleStyle(isOwnMessage)}>
            {msg.text && <div>{msg.text}</div>}
            {msg.fileUrl && msg.fileType?.startsWith('image/') && (
              <div style={{ marginTop: '0.5rem' }}>
                <img
                  src={msg.fileUrl}
                  alt="img"
                  style={{ maxWidth: '200px', borderRadius: 4, cursor: 'pointer' }}
                  onClick={() => antdMessage.info('Podgląd obrazu')}
                />
              </div>
            )}
            {msg.fileUrl && msg.fileType && !msg.fileType.startsWith('image/') && (
              <div style={{ marginTop: '0.5rem' }}>
                <FileOutlined style={{ fontSize: 18, marginRight: 8 }} />
                <a href={msg.fileUrl} download>
                  {truncateFileName(extractFileName(msg.fileUrl))}
                </a>
              </div>
            )}
            <div style={timeAndStatusStyle}>
              <span>
                {new Date(msg.timestamp).toLocaleTimeString('pl-PL', { hour: '2-digit', minute: '2-digit' })}
              </span>
              {isOwnMessage && (msg.isRead ? <span style={{ color: '#52c41a' }}>✓✓</span> : <span>✓</span>)}
            </div>
          </div>
        </div>
      );
      lastSenderId = msg.senderId;
    });
    return result;
  }, [sortedMessages, selectedChat]);

  const onFileChangeLocal = (info) => {
    const file = info.file.originFileObj || info.file;
    if (!file) return;
    setSelectedFile(file);
    if (file.type.startsWith('image/')) {
      const previewUrl = URL.createObjectURL(file);
      setPreviewImageUrl(previewUrl);
    } else {
      setPreviewImageUrl(null);
    }
  };

  const removeSelectedImage = () => {
    setSelectedFile(null);
    setPreviewImageUrl(null);
  };

  const handleSendMessage = async () => {
    if (!selectedChat || !user) {
      console.log('Нет selectedChat или user:', { selectedChat, user });
      return;
    }
    if (!message.trim() && !selectedFile) {
      console.log('Пустое сообщение и нет файла');
      return;
    }
    try {
      console.log('Отправляем сообщение, текст:', message, 'Файл:', selectedFile);
      
      const senderName = userInfo?.firstName
        ? `${userInfo.firstName} z zespołu wsparcia`
        : 'Administrator';
      const senderRole = 'admin';
  
      let fileUrl = null;
      let fileType = null;
      if (selectedFile) {
        const fileRef = storageRef(storage, `chatFiles/${selectedChat.id}/${Date.now()}_${selectedFile.name}`);
        await uploadBytes(fileRef, selectedFile);
        fileUrl = await getDownloadURL(fileRef);
        fileType = selectedFile.type;
        console.log('Файл загружен, URL:', fileUrl);
      }
  
      const newMsg = {
        text: message,
        fileUrl: fileUrl || null,
        fileType: fileType || null,
        sender: senderName,
        senderId: user.uid,
        senderRole,
        timestamp: new Date().toISOString(),
        isRead: false,
        readAt: null,
      };
  
      console.log('Новое сообщение:', newMsg);
      // Отправляем сообщение в чат (Realtime Database)
      await push(dbRef(db, `chats/${selectedChat.id}/messages`), newMsg);
      await set(dbRef(db, `chats/${selectedChat.id}/lastMessage`), {
        text: message,
        fileUrl: fileUrl || null,
        fileType: fileType || null,
        senderName,
        senderRole,
        timestamp: newMsg.timestamp,
        isRead: false,
      });
      console.log('Сообщение отправлено в Realtime Database');
  
      // Отправляем уведомление, если Firestore доступен
      if (firestore) {
        const notification = {
          title: 'Nowa wiadomość w czacie',
          text: `Otrzymano nową wiadomość od ${senderName}: ${message}`,
          createdAt: new Date(),
          isRead: false,
        };
        await addDoc(collection(firestore, `users/${selectedChat.creatorId}/notifications`), notification);
        console.log('Уведомление отправлено в Firestore');
      }
  
      // Очищаем поля ввода
      setMessage('');
      setSelectedFile(null);
      setPreviewImageUrl(null);
    } catch (err) {
      console.error('Ошибка при отправке сообщения:', err);
      antdMessage.error('Nie udało się wysłać wiadomości');
    }
  };

  return (
    <div style={chatWindowStyle}>
      {/* Заголовок чата */}
      <div style={headerStyle}>
        <div>
          <h2 style={{ margin: 0, fontSize: '16px' }}>{selectedChat?.chatName}</h2>
          <div style={{ color: '#555', fontSize: '14px' }}>
            <strong>Rozmowa z:</strong> {selectedChat?.creatorName || 'Nieznany'}
          </div>
          <div style={{ color: '#999', fontSize: '12px' }}>
            <strong>ID:</strong> {selectedChat?.creatorId}
          </div>
        </div>
        {selectedChat?.status !== 'closed' && (
          <Button danger onClick={openCloseModal}>
            Zamknij czat
          </Button>
        )}
      </div>

      {/* Список сообщений */}
      <div style={messagesStyle} className="hide-scrollbar">
        {formattedMessages}
        <div ref={messagesEndRef} />
      </div>

      {selectedChat?.status === 'closed' ? (
        <div style={{ padding: '16px', backgroundColor: '#fafafa' }}>
          <strong>Czat został zamknięty.</strong>
          <p>Powód: {selectedChat?.closeReason || '(brak powodu)'}</p>
          <p>
            Data:{' '}
            {selectedChat?.closedAt
              ? new Date(selectedChat.closedAt).toLocaleString('pl-PL')
              : ''}
          </p>
        </div>
      ) : (
        <div style={inputContainerStyle}>
          {previewImageUrl && selectedFile?.type?.startsWith('image/') && (
            <div style={{ marginBottom: '1rem' }}>
              <img
                src={previewImageUrl}
                alt="Preview"
                style={{ maxWidth: 100, marginRight: 10, borderRadius: 4 }}
              />
              <CloseCircleOutlined
                onClick={removeSelectedImage}
                style={{ fontSize: 24, color: 'red', cursor: 'pointer' }}
              />
            </div>
          )}
          {selectedFile && !selectedFile.type.startsWith('image/') && (
            <div style={{ marginBottom: '1rem' }}>
              <FileOutlined />
              <span style={{ marginLeft: 8 }}>
                {truncateFileName(selectedFile.name)}
              </span>
              <CloseCircleOutlined
                onClick={removeSelectedImage}
                style={{
                  fontSize: 24,
                  color: 'red',
                  cursor: 'pointer',
                  marginLeft: 8,
                }}
              />
            </div>
          )}
          <div style={{ display: 'flex', gap: '0.5rem' }}>
            <Input
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              onPressEnter={handleSendMessage}
              placeholder="Napisz wiadomość..."
            />
            <Upload
              beforeUpload={() => false}
              onChange={onFileChangeLocal}
              showUploadList={false}
            >
              <Button icon={<UploadOutlined />} />
            </Upload>
            <Button type="primary" onClick={handleSendMessage}>
              Wyślij
            </Button>
          </div>
        </div>
      )}

      {/* Модальное окно закрытия чата */}
      <CloseChatModal
        isCloseModalVisible={isCloseModalVisible}
        closeReason={closeReason}
        setCloseReason={setCloseReason}
        onCloseModal={onCloseModal}
        onCloseChat={onCloseChat}
      />
    </div>
  );
};

export default ChatWindow;

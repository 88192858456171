import React, { useState, useEffect, useRef } from 'react';
import { Card, Button, Input, Select, Table, Modal, message, AutoComplete, Row, Col, Divider, Spin } from 'antd';
import { ref, get, update } from 'firebase/database';
import { useFirebase } from '../FirebaseContext'; // Импортируем хук
import moment from 'moment';

const { Option } = Select;

const Rozliczenie = () => {
  const [searchValue, setSearchValue] = useState('');
  const [city, setCity] = useState('');
  const [week, setWeek] = useState('');
  const [cities, setCities] = useState([]);
  const [weeks, setWeeks] = useState([]);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [modalContent, setModalContent] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [drivers, setDrivers] = useState([]);
  const [showAllColumns, setShowAllColumns] = useState(false);
  const [selectedDriverId, setSelectedDriverId] = useState('');
  const [isApproveDisabled, setIsApproveDisabled] = useState(true);

  // Состояние для отображения оверлея при смене проекта
  const [isProjectChanging, setIsProjectChanging] = useState(false);

  const { currentFirebase } = useFirebase();
  const database = currentFirebase.database;

  // useRef для хранения предыдущего значения базы
  const prevDbRef = useRef(null);

  // Функция загрузки данных, которая гарантирует минимальное время отображения (1 сек)
  const loadAndDisplayData = async () => {
    const startTime = Date.now();
    setLoading(true);
    try {
      const weeks = await getAvailableWeeks();
      setWeeks(weeks);
      if (weeks.length > 0) {
        setWeek(weeks[0]);
        await loadAndDisplayWeekData(weeks[0]);
      }
      const driversList = await getDriversList();
      setDrivers(driversList);
    } catch (error) {
      console.error('Error fetching weeks or drivers:', error);
    }
    setLoading(false);
    const elapsed = Date.now() - startTime;
    const minimumDisplay = 1000; // 1 секунда
    const delay = Math.max(0, minimumDisplay - elapsed);
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  };

  useEffect(() => {
    const load = async () => {
      let showOverlay = false;
      if (prevDbRef.current && prevDbRef.current !== database) {
        showOverlay = true;
        setIsProjectChanging(true);
      }
      await loadAndDisplayData();
      prevDbRef.current = database;
      if (showOverlay) {
        setIsProjectChanging(false);
      }
    };
    load();
  }, [database]);

  useEffect(() => {
    filterData();
  }, [searchValue, city, data, week]);

  useEffect(() => {
    const hasCzekamNaZatwierdzenie = filteredData.some(item => item.status === 'Czekam na zatwierdzenie');
    setIsApproveDisabled(!hasCzekamNaZatwierdzenie);
  }, [filteredData]);

  const formatNumber = (num) => Number(num).toFixed(2);

  const getAvailableWeeks = async () => {
    try {
      const snapshot = await get(ref(database, 'drivers'));
      const driversData = snapshot.val();
      const weeksSet = new Set();
      for (const driverId in driversData) {
        const driverWeeks = driversData[driverId]?.weeks;
        if (driverWeeks) {
          Object.keys(driverWeeks).forEach((weekId) => weeksSet.add(weekId));
        }
      }
      const sortedWeeks = Array.from(weeksSet).sort((a, b) => {
        const [weekA, yearA] = a.split('-').map(Number);
        const [weekB, yearB] = b.split('-').map(Number);
        return yearB - yearA || weekB - weekA;
      });
      return sortedWeeks;
    } catch (error) {
      console.error('Error fetching available weeks: ', error);
      return [];
    }
  };

  const getDriversList = async () => {
    try {
      const snapshot = await get(ref(database, 'drivers'));
      const driversData = snapshot.val();
      const driversList = Object.keys(driversData).map(driverId => ({
        value: driverId,
      }));
      return driversList;
    } catch (error) {
      console.error('Error fetching drivers list:', error);
      return [];
    }
  };

  const loadAndDisplayWeekData = async (selectedWeek) => {
    setLoading(true);
    try {
      const snapshot = await get(ref(database, 'drivers'));
      const driversData = snapshot.val();
      const fetchedData = [];
      const citiesSet = new Set();
      for (const driverId in driversData) {
        const driverWeeks = driversData[driverId]?.weeks;
        if (driverWeeks && driverWeeks[selectedWeek]?.summary) {
          const summary = driverWeeks[selectedWeek].summary;
          if (summary.city) {
            citiesSet.add(summary.city);
          }
          fetchedData.push({
            driverId,
            city: summary.city || 'N/A',
            kursy: formatNumber(summary.kursy || 0),
            przychod_dodatkowy: formatNumber(summary.przychod_dodatkowy || 0),
            commission: formatNumber(summary.commission || 0),
            gotowka: formatNumber(summary.gotowka || 0),
            partner: summary.partner || 'N/A',
            service: Array.isArray(summary.service) ? summary.service.join(', ') : 'N/A',
            vat_bonus: formatNumber(summary.vat_bonus || 0),
            vat_dodatkowy: formatNumber(summary.vat_dodatkowy || 0),
            vat_przejazdy: formatNumber(summary.vat_przejazdy || 0),
            wynajem: formatNumber(summary.wynajem || 0),
            zus: formatNumber(summary.zus || 0),
            koztyUZ: formatNumber(summary.koztyUZ || 0),
            inne: formatNumber(summary.inne || 0),
            bonusPartnera: formatNumber(summary.bonusPartnera || 0),
            umowa_najmu: formatNumber(summary.umowa_najmu || 0),
            zwrot_kosztow: formatNumber(summary.zwrot_kosztow || 0),
            podatek_do_zaplaty: formatNumber(summary.podatek_do_zaplaty || 0),
            total: formatNumber(summary.total || 0),
            status: summary.status || 'N/A',
          });
        }
      }
      setCities(Array.from(citiesSet).sort());
      setData(fetchedData);
    } catch (error) {
      console.error('Error fetching week data:', error);
    }
    setLoading(false);
  };

  const handleLoadData = () => {
    loadAndDisplayWeekData(week);
  };

  const chunkArray = (array, chunkSize) => {
    const chunks = [];
    for (let i = 0; i < array.length; i += chunkSize) {
      chunks.push(array.slice(i, i + chunkSize));
    }
    return chunks;
  };

  const handleApprove = async () => {
    if (!week) {
      message.error('Proszę wybrać tydzień.');
      return;
    }
    const itemsToUpdate = filteredData.filter(item => item.status === 'Czekam na zatwierdzenie');
    if (itemsToUpdate.length === 0) {
      message.info('Нет данных для обновления.');
      return;
    }
    const batchSize = 50;
    const chunks = chunkArray(itemsToUpdate, batchSize);
    setLoading(true);
    try {
      for (const chunk of chunks) {
        const updates = {};
        chunk.forEach((item) => {
          updates[`drivers/${item.driverId}/weeks/${week}/summary/status`] = 'Rozliczenie dodane';
        });
        await update(ref(database), updates);
      }
      message.success('Statusy zostały zaktualizowane pomyślnie!');
      handleLoadData();
    } catch (error) {
      console.error('Error updating status:', error);
      message.error('Wystąpił błąd podczas aktualizacji statusu.');
    } finally {
      setLoading(false);
    }
  };

  const filterData = () => {
    const filtered = data.filter((item) => {
      const matchesSearch = item.driverId.toLowerCase().includes(searchValue.toLowerCase());
      const matchesCity = city === '' || item.city === city;
      return matchesSearch && matchesCity;
    });
    setFilteredData(filtered);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
  };

  const basicColumns = [
    { title: 'ID', dataIndex: 'driverId', key: 'driverId', sorter: (a, b) => a.driverId.localeCompare(b.driverId) },
    { title: 'Kursy', dataIndex: 'kursy', key: 'kursy', sorter: (a, b) => a.kursy - b.kursy },
    { title: 'Przychód dodatkowy', dataIndex: 'przychod_dodatkowy', key: 'przychod_dodatkowy', sorter: (a, b) => a.przychod_dodatkowy - b.przychod_dodatkowy },
    { title: 'Gotówka', dataIndex: 'gotowka', key: 'gotowka', sorter: (a, b) => a.gotowka - b.gotowka },
    { title: 'Bonus VAT', dataIndex: 'vat_bonus', key: 'vat_bonus', sorter: (a, b) => a.vat_bonus - b.vat_bonus },
    { title: 'Dodatkowy VAT', dataIndex: 'vat_dodatkowy', key: 'vat_dodatkowy', sorter: (a, b) => a.vat_dodatkowy - b.vat_dodatkowy },
    { title: 'VAT przejazdy', dataIndex: 'vat_przejazdy', key: 'vat_przejazdy', sorter: (a, b) => a.vat_przejazdy - b.vat_przejazdy },
    { title: 'Wynajem', dataIndex: 'wynajem', key: 'wynajem', sorter: (a, b) => a.wynajem - b.wynajem },
    { title: 'ZUS', dataIndex: 'zus', key: 'zus', sorter: (a, b) => a.zus - b.zus },
    { title: 'Inne', dataIndex: 'inne', key: 'inne', sorter: (a, b) => a.inne - b.inne },
    { title: 'Partner', dataIndex: 'partner', key: 'partner' },
    { title: 'Całkowity', dataIndex: 'total', key: 'total', sorter: (a, b) => a.total - b.total },
    {
      title: 'Szczegóły',
      key: 'details',
      render: (_, record) => (
        <Button onClick={() => showDetailsModal(record.driverId)}>Pokaż szczegóły</Button>
      ),
    },
  ];

  const additionalColumns = [
    { title: 'Miasto', dataIndex: 'city', key: 'city' },
    { title: 'Usługa', dataIndex: 'service', key: 'service' },
    { title: 'Koszty UZ', dataIndex: 'koztyUZ', key: 'koztyUZ', sorter: (a, b) => a.koztyUZ - b.koztyUZ },
    { title: 'Bonus partnera', dataIndex: 'bonusPartnera', key: 'bonusPartnera', sorter: (a, b) => a.bonusPartnera - b.bonusPartnera },
    { title: 'Umowa najmu', dataIndex: 'umowa_najmu', key: 'umowa_najmu', sorter: (a, b) => a.umowa_najmu - b.umowa_najmu },
    { title: 'Różnica', dataIndex: 'difference', key: 'difference', sorter: (a, b) => a.difference - b.difference },
    { title: 'Zwrot kosztów', dataIndex: 'zwrot_kosztow', key: 'zwrot_kosztow', sorter: (a, b) => a.zwrot_kosztow - b.zwrot_kosztow },
    { title: 'Podatek do zapłaty', dataIndex: 'podatek_do_zaplaty', key: 'podatek_do_zaplaty', sorter: (a, b) => a.podatek_do_zaplaty - b.podatek_do_zaplaty },
    { title: 'Status', dataIndex: 'status', key: 'status' },
  ];

  const columnsToShow = showAllColumns ? [...basicColumns, ...additionalColumns] : basicColumns;

  const showDetailsModal = async (driverId) => {
    setSelectedDriverId(driverId);
    setModalVisible(true);
    setModalContent('Pobieram dane...');
    try {
      // Получаем данные по приложениям
      const appsSnapshot = await get(ref(database, `drivers/${driverId}/weeks/${week}/apps`));
      const driverAppsData = appsSnapshot.val();
  
      // Получаем данные summary, где теперь содержится информация по invoices
      const summarySnapshot = await get(ref(database, `drivers/${driverId}/weeks/${week}/summary`));
      const driverSummaryData = summarySnapshot.val();
  
      // Формируем карточки для данных по приложениям
      const appCards = driverAppsData
        ? Object.entries(driverAppsData).map(([appName, appDetails]) => (
            <Card title={appName} key={appName} style={{ marginBottom: 16 }}>
              <Table
                dataSource={[
                  { key: '1', label: 'Miasto', value: appDetails.city },
                  { key: '2', label: 'Kursy', value: formatNumber(appDetails.kursy) },
                  { key: '3', label: 'Przychód dodatkowy', value: `${formatNumber(appDetails.przychod_dodatkowy)} PLN` },
                  { key: '4', label: 'Gotówka', value: `${formatNumber(appDetails.gotowka)} PLN` },
                  { key: '5', label: 'Prowizja aplikacji', value: `${formatNumber(appDetails.commission)} PLN` },
                  { key: '6', label: 'Napiwki', value: `${formatNumber(appDetails.tips)} PLN` },
                  { key: '7', label: 'VAT za przejazdy', value: `${formatNumber(appDetails.vat_przejazdy)} PLN` },
                  { key: '8', label: 'Data początkowa', value: appDetails.startDate },
                  { key: '9', label: 'Data końcowa', value: appDetails.endDate },
                  { key: '10', label: 'Total', value: `${formatNumber(appDetails.total)} PLN` },
                  { key: '11', label: 'Status', value: appDetails.status },
                ]}
                columns={[
                  { title: 'Label', dataIndex: 'label', key: 'label' },
                  { title: 'Value', dataIndex: 'value', key: 'value' },
                ]}
                pagination={false}
                showHeader={false}
              />
            </Card>
          ))
        : [];
  
      // Формируем карточку для отображения информации по invoices (фактурам)
      const invoicesCard =
        driverSummaryData && driverSummaryData.invoices ? (
          <Card title="Faktury" key="invoices">
            <Table
              dataSource={[
                {
                  key: '1',
                  label: 'Kandydaci',
                  value: driverSummaryData.invoices.candidates
                    ? driverSummaryData.invoices.candidates.join(', ')
                    : '',
                },
                {
                  key: '2',
                  label: 'Zaakceptowane',
                  value: driverSummaryData.invoices.accepted
                    ? driverSummaryData.invoices.accepted.join(', ')
                    : '',
                },
                {
                  key: '3',
                  label: 'Odrzucone',
                  value: driverSummaryData.invoices.rejected
                    ? driverSummaryData.invoices.rejected.join(', ')
                    : '',
                },
              ]}
              columns={[
                { title: 'Label', dataIndex: 'label', key: 'label' },
                { title: 'Value', dataIndex: 'value', key: 'value' },
              ]}
              pagination={false}
              showHeader={false}
            />
          </Card>
        ) : null;
  
      // Объединяем карточки и отображаем их
      const cards = (
        <div>
          {appCards}
          {invoicesCard}
        </div>
      );
      setModalContent(cards);
    } catch (error) {
      console.error('Error fetching driver details:', error);
      setModalContent('Błąd podczas pobierania danych.');
    }
  };
  
  return (
    <div>
      <Row gutter={16} style={{ marginBottom: '16px', alignItems: 'center' }}>
        <Col span={5}>
          <AutoComplete
            id="search"
            options={drivers}
            placeholder="Wyszukaj kierowcę"
            value={searchValue}
            onChange={(value) => setSearchValue(value)}
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={5}>
          <AutoComplete
            id="city-select"
            options={cities.map((city) => ({ value: city }))}
            placeholder="Wybierz miasto"
            value={city}
            onChange={(value) => setCity(value)}
            style={{ width: '100%' }}
            allowClear
          />
        </Col>
        <Col span={5}>
          <Select
            id="week-select"
            value={week}
            onChange={(value) => {
              setWeek(value);
              loadAndDisplayWeekData(value);
            }}
            style={{ width: '100%' }}
            placeholder="Wybierz tydzień"
          >
            {weeks.map((week) => (
              <Option key={week} value={week}>
                {week}
              </Option>
            ))}
          </Select>
        </Col>
        <Col span={4}>
          <Button type="primary" onClick={handleApprove} style={{ width: '100%' }} disabled={isApproveDisabled}>
            Zatwierdź rozliczenia
          </Button>
        </Col>
        <Col span={5}>
          <Button onClick={() => setShowAllColumns(!showAllColumns)} style={{ width: '100%' }}>
            {showAllColumns ? 'Pokaż mniej' : 'Pokaż więcej'}
          </Button>
        </Col>
      </Row>
      <Divider orientation="left" plain>
        Rozliczenia kierowców
      </Divider>
      <Table
        id="data-table"
        columns={columnsToShow}
        dataSource={filteredData}
        loading={loading}
        pagination={{
          current: currentPage,
          pageSize: rowsPerPage,
          onChange: handlePageChange,
          total: filteredData.length,
          showSizeChanger: true,
          pageSizeOptions: ['10', '15', '20', '25'],
          onShowSizeChange: handleRowsPerPageChange,
        }}
        rowKey="driverId"
      />

      <Modal
        title={`Szczegóły dla ${selectedDriverId} - Tydzień ${week}`}
        visible={modalVisible}
        onCancel={() => setModalVisible(false)}
        footer={null}
        width={1000}
      >
        {modalContent}
      </Modal>

      {/* Оверлей отображается только при смене базы */}
      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          transition: 'opacity 0.5s ease',
        }}>
          <Spin size="large" />
          <div style={{ marginTop: 16, fontSize: 18, color: '#333' }}>
            Przełączam projekt...
          </div>
        </div>
      )}
    </div>
  );
};

export default Rozliczenie;

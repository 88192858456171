// components/Chat/CloseChatModal.jsx
import React from 'react';
import { Modal, Form, Input } from 'antd';

const CloseChatModal = ({
  isCloseModalVisible,
  closeReason,
  setCloseReason,
  onCloseModal,
  onCloseChat,
}) => {
  return (
    <Modal
      title="Powód zamknięcia czatu"
      visible={isCloseModalVisible}
      onCancel={onCloseModal}
      onOk={onCloseChat}
      okText="Zamknij czat"
      cancelText="Anuluj"
    >
      <Form layout="vertical">
        <Form.Item label="Podaj powód zamknięcia">
          <Input.TextArea
            rows={4}
            value={closeReason}
            onChange={(e) => setCloseReason(e.target.value)}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default CloseChatModal;

import React, { useState, useEffect } from 'react';
import { Card, Button, List, notification } from 'antd';
import { ref, onValue } from 'firebase/database';
import { useFirebase } from '../FirebaseContext'; // Получаем базу через контекст
import { useNavigate } from 'react-router-dom';

const OpenChats = () => {
  const [chats, setChats] = useState([]);
  const navigate = useNavigate();

  // Получаем актуальный экземпляр Firebase
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;

  useEffect(() => {
    const chatsRef = ref(db, 'chats');

    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const openChats = [];
      snapshot.forEach((snap) => {
        const chat = { id: snap.key, ...snap.val() };

        // Устанавливаем уведомления для каждого чата
        const messagesRef = ref(db, `chats/${chat.id}/messages`);
        onValue(messagesRef, (messageSnapshot) => {
          let notificationShown = false; // Флаг, чтобы уведомление показывалось один раз на чат
          messageSnapshot.forEach((messageSnap) => {
            const message = messageSnap.val();
            if (!message.isRead && message.senderRole === 'user' && !notificationShown) {
              notification.open({
                message: `Nowa wiadomość od ${message.sender}`,
                description: message.text,
                placement: 'topRight',
                onClick: () => {
                  navigate(`/chat`, { state: { selectedChatId: chat.id } });
                },
              });
              notificationShown = true;
            }
          });
        });

        if (chat.status === 'open') {
          openChats.push(chat);
        }
      });
      setChats(openChats);
    });

    return () => unsubscribe();
  }, [db, navigate]);

  const openChat = (chatId) => {
    navigate(`/chat`, { state: { selectedChatId: chatId } });
  };

  return (
    <Card title="Otwarte zgłoszenia">
      <List
        itemLayout="horizontal"
        dataSource={chats}
        renderItem={(chat) => (
          <List.Item
            actions={[
              <Button type="primary" onClick={() => openChat(chat.id)}>
                Przejdź do rozmowy
              </Button>,
            ]}
          >
            <List.Item.Meta
              title={`Temat: ${chat.chatName || 'Nieизвестny чат'}`}
              description={
                <>
                  <p>Kierowca: {chat.creatorName || 'Nieznany użytkownik'}</p>
                  <p>Status: {chat.status || 'Nieизвестно'}</p>
                  <p>
                    Data utworzenia:{' '}
                    {chat.timestamp
                      ? new Date(chat.timestamp).toLocaleString('pl-PL')
                      : 'Nieизвестно'}
                  </p>
                </>
              }
            />
          </List.Item>
        )}
      />
    </Card>
  );
};

export default OpenChats;

import React, { useState } from 'react';
import { Button, Form, Input, message, Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from "firebase/auth";
import { useFirebase } from '../FirebaseContext'; // Импортируем хук из контекста
import AuthLayout from '../components/AuthLayout';

const { Title, Text } = Typography;

const Login = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  // Получаем актуальный экземпляр Firebase через контекст и извлекаем auth
  const { currentFirebase } = useFirebase();
  const auth = currentFirebase.auth;

  const onFinish = async (values) => {
    setLoading(true);
    try {
      await signInWithEmailAndPassword(auth, values.email, values.password);
      message.success('Zalogowano pomyślnie');
      navigate('/');
    } catch (error) {
      message.error(error.message);
    }
    setLoading(false);
  };

  return (
    <AuthLayout>
      <div style={{ textAlign: 'center', marginBottom: '24px' }}>
        <Title level={2}>Logowanie</Title>
        <Text>
          Jeśli jesteś nowym użytkownikiem, proszę{' '}
          <Link to="/register">zarejestruj się tutaj</Link>.
        </Text>
      </div>
      <Form name="login" onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Email"
          name="email"
          rules={[{ required: true, message: 'Proszę podać email!' }]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Hasło"
          name="password"
          rules={[{ required: true, message: 'Proszę podać hasło!' }]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit" loading={loading} block>
            Zaloguj się
          </Button>
        </Form.Item>
      </Form>
    </AuthLayout>
  );
};

export default Login;

import { useState, useEffect } from 'react';
import { ref, onValue } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';

export default function useChatMessages(chatId) {
  const [messages, setMessages] = useState([]);
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;

  useEffect(() => {
    if (!chatId) return;
    const messagesRef = ref(db, `chats/${chatId}/messages`);
    const unsubscribe = onValue(messagesRef, (snapshot) => {
      const msgs = [];
      snapshot.forEach((snap) => {
        msgs.push({ id: snap.key, ...snap.val() });
      });
      setMessages(msgs);
    });

    return () => unsubscribe();
  }, [chatId, db]);

  return messages;
}

import React, { useState, useEffect } from 'react';
import { Input, Select, Button, Table, Pagination, Row, Col, DatePicker, message, Modal, Spin, ConfigProvider } from 'antd';
import { ref, get, set } from 'firebase/database';
import { useFirebase } from '../FirebaseContext'; // Используем контекст вместо прямого импорта базы
import moment from 'moment';
import 'moment/locale/pl'; // Подключаем локаль moment.js для польского языка
import plPL from 'antd/es/locale/pl_PL'; // Импортируем польскую локализацию для Ant Design

const { Option } = Select;
const { RangePicker } = DatePicker;

moment.updateLocale('pl', {
  week: {
    dow: 1, // Устанавливаем понедельник первым днем недели
    doy: 4, // Первая неделя года начинается с 4 января (ISO 8601)
  },
});

const Faktury = () => {
  // Получаем текущий экземпляр Firebase через контекст
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database; // Используем актуальную базу данных

  const [searchValue, setSearchValue] = useState('');
  const [status, setStatus] = useState('all');
  const [type, setType] = useState('all');
  const [statuses, setStatuses] = useState([]);
  const [types, setTypes] = useState([]);
  const [dateRange, setDateRange] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(15);
  const [invoicesData, setInvoicesData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [downloading, setDownloading] = useState(false);
  const [sorterInfo, setSorterInfo] = useState({});

  // Добавляем зависимость от db: при изменении проекта (db меняется) вызываем загрузку данных
  useEffect(() => {
    loadAndDisplayData();
  }, [db]);

  useEffect(() => {
    filterData();
  }, [searchValue, status, type, dateRange, invoicesData, sorterInfo]);

  const loadAndDisplayData = async () => {
    setLoading(true);
    try {
      // Используем db из контекста
      const snapshot = await get(ref(db, '/drivers'));
      const drivers = snapshot.val();
      let invoices = [];
      let statusesSet = new Set();
      let typesSet = new Set();

      for (let driverId in drivers) {
        const driverData = drivers[driverId];
        if (driverData.invoices) {
          for (let invoiceId in driverData.invoices) {
            const invoice = driverData.invoices[invoiceId];
            const invoiceDate = new Date(invoice.timestamp);

            if (isNaN(invoiceDate)) {
              console.error("Invalid Date for invoice timestamp:", invoice.timestamp);
              continue;
            }

            invoices.push({
              ...invoice,
              driverId,
              invoiceId,
              addedDate: moment(invoiceDate).format('YYYY-MM-DD HH:mm:ss'),
            });

            if (invoice.status) statusesSet.add(invoice.status);
            if (invoice.type) typesSet.add(invoice.type);
          }
        }
      }

      setStatuses(Array.from(statusesSet));
      setTypes(Array.from(typesSet));
      setInvoicesData(invoices);
      setFilteredData(invoices);
      setCurrentPage(1);
    } catch (error) {
      console.error('Error fetching invoices data: ', error);
      message.error('Błąd podczas pobierania danych.');
    }
    setLoading(false);
  };

  const filterData = () => {
    const lowerCaseSearchValue = searchValue.toLowerCase();
    let filtered = invoicesData.filter(invoice => {
      const matchesSearch = (
        (invoice.driverId?.toLowerCase().includes(lowerCaseSearchValue) || '') ||
        (invoice.numerfaktury?.toLowerCase().includes(lowerCaseSearchValue) || '') ||
        (invoice.nipseller?.toLowerCase().includes(lowerCaseSearchValue) || '') ||
        (invoice.rejectionComment?.toLowerCase().includes(lowerCaseSearchValue) || '')
      );

      const matchesStatus = status === 'all' || invoice.status === status;
      const matchesType = type === 'all' || invoice.type === type;

      const matchesDateRange = dateRange
        ? (invoice.timestamp >= dateRange[0].startOf('day').valueOf() && 
           invoice.timestamp <= dateRange[1].endOf('day').valueOf())
        : true;

      return matchesSearch && matchesStatus && matchesType && matchesDateRange;
    });

    if (sorterInfo.order && sorterInfo.field) {
      filtered.sort((a, b) => {
        if (sorterInfo.order === 'ascend') {
          return a[sorterInfo.field] > b[sorterInfo.field] ? 1 : -1;
        }
        return a[sorterInfo.field] < b[sorterInfo.field] ? 1 : -1;
      });
    }

    setFilteredData(filtered);
  };

  const updateInvoiceStatus = async (driverId, invoiceId, newStatus) => {
    try {
      const refPath = `/drivers/${driverId}/invoices/${invoiceId}/status`;
      await set(ref(db, refPath), newStatus);
      message.success('Status updated successfully.');
      setFilteredData(prevData =>
        prevData.map(invoice =>
          invoice.invoiceId === invoiceId ? { ...invoice, status: newStatus } : invoice
        )
      );
    } catch (error) {
      console.error('Error updating status: ', error);
      message.error('Błąd podczas aktualizacji statusu.');
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const handleRowsPerPageChange = (current, size) => {
    setRowsPerPage(size);
    setCurrentPage(1);
  };

  const showModal = (fileURL) => {
    setCurrentImage(fileURL);
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setCurrentImage(null);
  };

  const handleDownload = async () => {
    if (currentImage) {
      setDownloading(true);
      try {
        const response = await fetch(currentImage);
        const blob = await response.blob();
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');

        const invoice = filteredData.find(inv => inv.fileURL === currentImage);
        const fileName = `${invoice.numerfaktury}_${invoice.driverId}.png`;

        link.href = url;
        link.setAttribute('download', fileName);
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
      } catch (error) {
        message.error('Błąd podczas pobierania pliku.');
      } finally {
        setDownloading(false);
      }
    }
  };

  const formatNumber = (value) => {
    if (typeof value === 'number') {
      return value.toFixed(2);
    }
    return value;
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setSorterInfo(sorter);
    setCurrentPage(pagination.current);
    filterData();
  };

  const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

  const columns = [
    { title: 'Data dodania', dataIndex: 'addedDate', key: 'addedDate', sorter: true },
    { title: 'Driver ID', dataIndex: 'driverId', key: 'driverId', sorter: true },
    { title: 'Numer Faktury', dataIndex: 'numerfaktury', key: 'numerfaktury', sorter: true },
    { title: 'Data zakupu', dataIndex: 'purchaseDate', key: 'purchaseDate', sorter: true },
    { title: 'Typ', dataIndex: 'type', key: 'type', sorter: true },
    { title: 'Numer rejestracyjny auta', dataIndex: 'registrationNumber', key: 'registrationNumber', sorter: true },
    { title: 'NIP Sprzedawcy', dataIndex: 'nipseller', key: 'nipseller', sorter: true },
    { title: 'Litry', dataIndex: 'liters', key: 'liters', sorter: true, render: (value) => formatNumber(value) },
    { title: 'Rodzaj paliwa', dataIndex: 'fuelType', key: 'fuelType', sorter: true },
    { title: 'Kwota brutto', dataIndex: 'grossAmount', key: 'grossAmount', sorter: true, render: (value) => formatNumber(value) },
    { title: 'Stawka VAT', dataIndex: 'vatRate', key: 'vatRate', sorter: true, render: (value) => formatNumber(value) },
    { title: 'Kwota Netto', dataIndex: 'netAmount', key: 'netAmount', sorter: true, render: (value) => formatNumber(value) },
    { title: 'Kwota VAT', dataIndex: 'vatAmount', key: 'vatAmount', sorter: true, render: (value) => formatNumber(value) },
    { title: 'VAT Do zwrotu', dataIndex: 'vatReturn', key: 'vatReturn', sorter: true, render: (value) => formatNumber(value) },
    { title: 'Status', dataIndex: 'status', key: 'status', sorter: true },
    {
      title: 'Change Status', key: 'changeStatus', render: (_, record) => (
        <Select defaultValue={record.status} onChange={(value) => updateInvoiceStatus(record.driverId, record.invoiceId, value)}>
          <Option value="W trakcie sprawdzenia">W trakcie sprawdzenia</Option>
          <Option value="zaakceptowany">zaakceptowany</Option>
          <Option value="odrzucony">odrzucony</Option>
        </Select>
      )
    },
    {
      title: 'Plik', key: 'file', render: (_, record) => (
        record.fileURL ? <Button onClick={() => showModal(record.fileURL)}>Podgląd</Button> : 'Brak'
      )
    },
  ];

  return (
    <ConfigProvider locale={plPL}>
      <div>
        <Row gutter={16} style={{ marginBottom: '16px', alignItems: 'center' }}>
          <Col span={4}>
            <Input
              id="search"
              placeholder="Wyszukaj kierowcę"
              value={searchValue}
              onChange={(e) => setSearchValue(e.target.value)}
              style={{ width: '100%' }}
            />
          </Col>
          <Col span={4}>
            <Select id="status-select" value={status} onChange={(value) => setStatus(value)} style={{ width: '100%' }}>
              <Option value="all">Wszystkie</Option>
              {statuses.map((status) => (
                <Option key={status} value={status}>{status}</Option>
              ))}
            </Select>
          </Col>
          <Col span={4}>
            <Select id="type-select" value={type} onChange={(value) => setType(value)} style={{ width: '100%' }}>
              <Option value="all">Wszystkie</Option>
              {types.map((type) => (
                <Option key={type} value={type}>{type}</Option>
              ))}
            </Select>
          </Col>
          <Col span={8}>
            <RangePicker
              id="date-range"
              style={{ width: '100%' }}
              value={dateRange}
              onChange={(dates) => setDateRange(dates || null)}
              format="YYYY-MM-DD"
            />
          </Col>
        </Row>

        <Table
          id="data-table"
          columns={columns}
          dataSource={paginatedData}
          pagination={false}
          rowKey="invoiceId"
          loading={loading}
          onChange={handleTableChange}
        />

        <Pagination
          id="pagination"
          style={{ textAlign: 'right', marginTop: '16px' }}
          current={currentPage}
          total={filteredData.length}
          pageSize={rowsPerPage}
          onChange={handlePageChange}
          pageSizeOptions={['10', '15', '20', '25']}
          onShowSizeChange={handleRowsPerPageChange}
          showSizeChanger
        />

        <Modal
          title={
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>Podgląd Pliku</span>
            </div>
          }
          visible={isModalVisible}
          onCancel={handleCancel}
          footer={
            <Button onClick={handleDownload} disabled={!currentImage || downloading}>
              {downloading ? <Spin /> : 'Pobierz'}
            </Button>
          }
        >
          {currentImage && <img src={currentImage} alt="Invoice Preview" style={{ width: '100%' }} />}
        </Modal>
      </div>
    </ConfigProvider>
  );
};

export default Faktury;

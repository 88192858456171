import React, { useState, useEffect } from 'react';
import { doc, getDoc } from 'firebase/firestore';
import { ref, onValue } from 'firebase/database';
import { useFirebase } from '../FirebaseContext'; // Импортируем хук из контекста
import './Chat.css';

import ChatList from '../components/chat/ChatList';
import ChatWindow from '../components/chat/ChatWindow';

const Chat = () => {
  const [chats, setChats] = useState([]);
  const [selectedChat, setSelectedChat] = useState(null);
  const [user, setUser] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  useEffect(() => {
    console.log('Selected chat changed:', selectedChat);
  }, [selectedChat]);
  
  // Получаем текущий экземпляр Firebase из контекста
  const { currentFirebase } = useFirebase();
  // Деструктуризуем нужные сервисы из currentFirebase
  const { auth, database, firestore } = currentFirebase;

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
      console.log('Auth state changed:', currentUser);
      if (currentUser) {
        setUser(currentUser);
        const userDocRef = doc(firestore, 'users', currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
        if (userDocSnap.exists()) {
          setUserInfo(userDocSnap.data());
        }
      }
    });
    return () => unsubscribe();
  }, [auth, firestore]);
  
  

  useEffect(() => {
    const chatsRef = ref(database, 'chats');
    const unsubscribe = onValue(chatsRef, (snapshot) => {
      const loadedChats = [];
      snapshot.forEach((snap) => {
        const chat = { id: snap.key, ...snap.val() };
        loadedChats.push(chat);
      });
      console.log('Loaded chats:', loadedChats);
      setChats(loadedChats);
    });
    return () => unsubscribe();
  }, [database]);
  

  const handleSelectChat = (chatId) => {
    const chat = chats.find((c) => c.id === chatId);
    console.log('handleSelectChat, chatId:', chatId, 'Found chat:', chat);
    if (chat) {
      setSelectedChat(chat);
    }
  };
  

  // Стили для layout
  const containerStyle = {
    display: 'flex',
    height: '100vh',     // На весь экран
    margin: 0,
    padding: 0,
    overflow: 'hidden',  // Без скролла для всей страницы
  };

  const leftColStyle = {
    width: '30%',
    overflowY: 'auto',   // Вертикальный скролл для списка чатов
  };

  const rightColStyle = {
    width: '70%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',  // Без скролла справа
  };

  return (
    <div style={containerStyle}>
      {/* Левая колонка — список чатов */}
      <div style={leftColStyle} className="hide-scrollbar">
        <ChatList
          chats={chats}
          chatStatus="open"
          onSelectChat={handleSelectChat}
        />
      </div>

      {/* Правая колонка — окно выбранного чата */}
      <div style={rightColStyle}>
        {selectedChat ? (
          <ChatWindow
            selectedChat={selectedChat}
            user={user}
            userInfo={userInfo}
            database={database}    // Передаем Realtime Database из текущего экземпляра
            firestore={firestore}  // И Firestore (при необходимости)
          />
        ) : (
          <div style={{ padding: '20px' }}>
            <h2>Wybierz czat z listy</h2>
          </div>
        )}
      </div>
    </div>
  );
};

export default Chat;

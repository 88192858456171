import React, { useEffect, useState } from 'react';
import { Table, Button, message, Select, Modal, Form, Input, DatePicker, AutoComplete, Upload, Row, Col, Divider, Spin } from 'antd';
import { useFirebase } from '../FirebaseContext'; // Контекст для доступа к текущему Firebase
import { ref, onValue, update, remove, push } from 'firebase/database';
import { ref as storageRef, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { UploadOutlined } from '@ant-design/icons';
import moment from 'moment';

const { RangePicker } = DatePicker;

const Samochody = () => {
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;
  const storageInstance = currentFirebase.storage;

  const [cars, setCars] = useState([]);
  const [drivers, setDrivers] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [form] = Form.useForm();
  const [uploadingFiles, setUploadingFiles] = useState({});
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState(false);
  const [selectedCar, setSelectedCar] = useState(null);
  const [imageModalVisible, setImageModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [filterValue, setFilterValue] = useState('');
  const [isProjectChanging, setIsProjectChanging] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true); // Флаг для отслеживания первого рендера

  const handleFilterChange = (e) => {
    setFilterValue(e.target.value);
  };

  const filteredCars = cars.filter(car => {
    const lowercasedFilter = filterValue.toLowerCase();
    return Object.keys(car).some(key =>
      car[key] && car[key].toString().toLowerCase().includes(lowercasedFilter)
    );
  });

  const showImageModal = (imageUrl) => {
    setCurrentImage(imageUrl);
    setImageModalVisible(true);
  };

  useEffect(() => {
    const startTime = Date.now();
    // Показываем спиннер только если это не первый рендер (то есть переключение проекта)
    if (!firstLoad) {
      setIsProjectChanging(true);
    }

    const driversRef = ref(db, 'drivers');
    const carsRef = ref(db, 'admin/carsrent');

    onValue(driversRef, (snapshot) => {
      setDrivers(snapshot.val() || {});
    });

    onValue(carsRef, (snapshot) => {
      const carsData = snapshot.val() || {};
      const carsList = Object.keys(carsData).map(carId => ({
        ...carsData[carId],
        key: carId,
      }));
      setCars(carsList);
      if (!firstLoad) {
        // Обеспечиваем минимальное время отображения спиннера (1 секунда)
        const elapsed = Date.now() - startTime;
        const minimumDisplay = 1000;
        const delay = Math.max(0, minimumDisplay - elapsed);
        setTimeout(() => {
          setIsProjectChanging(false);
        }, delay);
      }
    });

    if (firstLoad) {
      setFirstLoad(false);
    }
  }, [db]);

  const handleSaveCar = (carId, values) => {
    const updatedFields = {};

    if (values.make !== selectedCar.make) updatedFields.make = values.make;
    if (values.model !== selectedCar.model) updatedFields.model = values.model;
    if (values.number !== selectedCar.number) updatedFields.number = values.number;
    if (values.rent !== selectedCar.rent) updatedFields.rent = values.rent;
    if (values.status !== selectedCar.status) updatedFields.status = values.status;

    if (values.dowodExpiry && moment.isMoment(values.dowodExpiry) && !values.dowodExpiry.isSame(moment(selectedCar.dowodExpiry))) {
      updatedFields.dowodExpiry = values.dowodExpiry.format('YYYY-MM-DD');
    }

    if (values.przegladExpiry && moment.isMoment(values.przegladExpiry) && !values.przegladExpiry.isSame(moment(selectedCar.przegladExpiry))) {
      updatedFields.przegladExpiry = values.przegladExpiry.format('YYYY-MM-DD');
    }

    if (
      values.insurance &&
      Array.isArray(values.insurance) &&
      values.insurance.length === 2 &&
      moment.isMoment(values.insurance[0]) &&
      moment.isMoment(values.insurance[1]) &&
      (values.insurance[0].format('YYYY-MM-DD') !== selectedCar.insurance?.startDate ||
        values.insurance[1].format('YYYY-MM-DD') !== selectedCar.insurance?.endDate)
    ) {
      updatedFields.insurance = {
        startDate: values.insurance[0].format('YYYY-MM-DD'),
        endDate: values.insurance[1].format('YYYY-MM-DD'),
      };
    }

    if (values.taxiCertificate && moment.isMoment(values.taxiCertificate) && !values.taxiCertificate.isSame(moment(selectedCar.taxiCertificate))) {
      updatedFields.taxiCertificate = values.taxiCertificate.format('YYYY-MM-DD');
    }

    if (values.comments !== selectedCar.comments) updatedFields.comments = values.comments;
    if (values.driver !== selectedCar.driver) updatedFields.driver = values.driver || 'Brak';

    if (Object.keys(updatedFields).length > 0) {
      update(ref(db, `admin/carsrent/${carId}`), updatedFields)
        .then(() => {
          message.success('Dane pojazdu zostały pomyślnie zaktualizowane.');
          setIsEditing(false);
          setIsDetailsModalOpen(false);
        })
        .catch((error) => {
          message.error(`Wystąpił błąd podczas aktualizacji danych: ${error}`);
        });
    } else {
      message.info('Nie ma żadnych zmian do zapisania.');
      setIsEditing(false);
      setIsDetailsModalOpen(false);
    }
  };

  const showCarDetails = (car) => {
    setSelectedCar(car);
    setIsDetailsModalOpen(true);

    form.setFieldsValue({
      make: car.make,
      model: car.model,
      number: car.number,
      rent: car.rent,
      status: car.status,
      dowodExpiry: car.dowodExpiry ? moment(car.dowodExpiry) : null,
      przegladExpiry: car.przegladExpiry ? moment(car.przegladExpiry) : null,
      insurance: car.insurance ? [moment(car.insurance.startDate), moment(car.insurance.endDate)] : [],
      taxiCertificate: car.taxiCertificate ? moment(car.taxiCertificate) : null,
      comments: car.comments,
      driver: car.driver || 'Brak',
    });
  };

  const handleDeleteCar = (carId) => {
    const carRef = ref(db, `admin/carsrent/${carId}`);
    remove(carRef)
      .then(() => {
        message.success('Samochód został usunięty.');
        setIsDetailsModalOpen(false);
      })
      .catch((error) => {
        message.error(`Wystąpił błąd podczas usuwania danych: ${error}`);
      });
  };

  const handleDriverChange = (carId, selectedDriverId) => {
    const car = cars.find(car => car.key === carId);
    let updatedCar = { ...car };

    if (selectedDriverId && selectedDriverId !== 'Brak') {
      if (drivers[selectedDriverId].car && drivers[selectedDriverId].car !== carId) {
        message.error(`Kierowca ${selectedDriverId} jest już przypisany do innego samochodu!`);
        return;
      }

      if (car.driver && car.driver !== selectedDriverId && car.driver !== 'Brak') {
        const oldDriver = { ...drivers[car.driver] };
        delete oldDriver.car;
        update(ref(db, `drivers/${car.driver}`), oldDriver);
      }

      updatedCar.driver = selectedDriverId;
      updatedCar.status = "wynajęte";
      const selectedDriver = { ...drivers[selectedDriverId], car: carId };
      update(ref(db, `drivers/${selectedDriverId}`), selectedDriver);
    } else {
      if (car.driver && car.driver !== 'Brak') {
        const oldDriver = { ...drivers[car.driver] };
        delete oldDriver.car;
        update(ref(db, `drivers/${car.driver}`), oldDriver);
      }
      updatedCar.driver = 'Brak';
      updatedCar.status = "do wynajęcia";
    }

    handleSaveCar(carId, updatedCar);
  };

  const handleRemoveDriver = (carId) => {
    const car = cars.find(car => car.key === carId);
    if (car.driver && car.driver !== 'Brak') {
      const oldDriverId = car.driver;
      const driverRef = ref(db, `drivers/${oldDriverId}`);

      update(driverRef, { car: null })
        .then(() => {
          const updatedCar = { ...car, driver: 'Brak', status: 'do wynajęcia' };
          handleSaveCar(carId, updatedCar);
          message.success('Kierowca został usunięty z samochodu.');
        })
        .catch((error) => {
          message.error(`Wystąpił błąd podczas usuwania kierowcy: ${error}`);
        });
    }
  };

  const confirmDeleteCar = () => {
    Modal.confirm({
      title: 'Czy na pewno chcesz usunąć ten samochód?',
      content: 'Ta operacja jest nieodwracalna.',
      onOk() {
        handleDeleteCar(selectedCar.key);
      },
      onCancel() {
        message.info('Usunięcie samochodu zostało anulowane.');
      },
    });
  };

  const handleAddCar = async (values) => {
    try {
      // Загрузка изображений в Firebase Storage и получение URL-адресов
      const uploadFile = async (file) => {
        const storageReference = storageRef(storageInstance, `cars/${file.name}`);
        const uploadTask = uploadBytesResumable(storageReference, file.originFileObj);
        await uploadTask;
        return await getDownloadURL(storageReference);
      };

      const techPassportFrontUrl = uploadingFiles.techPassportFront ? await uploadFile(uploadingFiles.techPassportFront[0]) : null;
      const techPassportBackUrl = uploadingFiles.techPassportBack ? await uploadFile(uploadingFiles.techPassportBack[0]) : null;
      const insurancePhotoUrl = uploadingFiles.insurancePhoto ? await uploadFile(uploadingFiles.insurancePhoto[0]) : null;
      const taxiCertificatePhotoUrl = uploadingFiles.taxiCertificatePhoto ? await uploadFile(uploadingFiles.taxiCertificatePhoto[0]) : null;

      // Преобразование дат в строки
      const insuranceDates = values.insurance ? values.insurance.map(date => date.format('YYYY-MM-DD')) : [null, null];
      const techPassportDates = values.techPassport ? values.techPassport.map(date => date.format('YYYY-MM-DD')) : [null, null];
      const dowodExpiryDate = values.dowodExpiry ? values.dowodExpiry.format('YYYY-MM-DD') : null;
      const przegladExpiryDate = values.przegladExpiry ? values.przegladExpiry.format('YYYY-MM-DD') : null;
      const taxiCertificateDate = values.taxiCertificate ? values.taxiCertificate.format('YYYY-MM-DD') : null;

      // Установка статуса машины в зависимости от наличия водителя
      const carStatus = values.driver && values.driver !== 'Brak' ? "wynajęte" : "do wynajęcia";

      const carData = {
        make: values.make,
        model: values.model,
        number: values.number,
        rent: values.rent,
        paymentType: values.paymentType,
        comments: values.comments,
        driver: values.driver || 'Brak',
        status: carStatus,
        insurance: {
          startDate: insuranceDates[0],
          endDate: insuranceDates[1],
        },
        techPassport: {
          startDate: techPassportDates[0],
          endDate: techPassportDates[1],
        },
        taxiCertificate: taxiCertificateDate,
        dowodExpiry: dowodExpiryDate,
        przegladExpiry: przegladExpiryDate,
        techPassportFront: techPassportFrontUrl,
        techPassportBack: techPassportBackUrl,
        insurancePhoto: insurancePhotoUrl,
        taxiCertificatePhoto: taxiCertificatePhotoUrl,
      };

      // Добавляем машину в базу данных
      const carRef = await push(ref(db, 'admin/carsrent'), carData);
      const carId = carRef.key;

      // Если водитель назначен, обновляем его данные
      if (values.driver && values.driver !== 'Brak') {
        const driverRef = ref(db, `drivers/${values.driver}`);
        await update(driverRef, { car: carId });
      }

      message.success('Samochód został dodany!');
      setIsModalOpen(false);
      form.resetFields();
    } catch (error) {
      message.error(`Wystąpił błąd podczas dodawania samochodu: ${error}`);
    }
  };

  const handleUploadChange = (fieldName) => ({ fileList }) => {
    setUploadingFiles(prevState => ({
      ...prevState,
      [fieldName]: fileList,
    }));
  };

  const columns = [
    {
      title: 'Marka',
      dataIndex: 'make',
      key: 'make',
    },
    {
      title: 'Model',
      dataIndex: 'model',
      key: 'model',
    },
    {
      title: 'Numer',
      dataIndex: 'number',
      key: 'number',
    },
    {
      title: 'Czynsz',
      dataIndex: 'rent',
      key: 'rent',
    },   
    {
      title: 'Kierowca',
      dataIndex: 'driver',
      key: 'driver',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Akcje',
      key: 'actions',
      render: (_, record) => (
        <>
          <Button onClick={() => showCarDetails(record)}>Szczegóły</Button>
        </>
      ),
    },
  ];

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
        <Input
          placeholder="Filtruj samochody"
          value={filterValue}
          onChange={handleFilterChange}
          style={{ width: '100%', marginRight: '10px' }}
        />
        <Button type="primary" onClick={() => setIsModalOpen(true)}>
          Dodaj Samochód
        </Button>
      </div>
      <Table columns={columns} dataSource={filteredCars} style={{ marginTop: 20 }} />

      <Modal
        title="Dodaj Nowy Samochód"
        visible={isModalOpen}
        onCancel={() => setIsModalOpen(false)}
        footer={null}
      >
        <Form
          form={form}
          layout="vertical"
          onFinish={handleAddCar}
        >
          <Form.Item
            label="Marka"
            name="make"
            rules={[{ required: true, message: 'Proszę wpisać markę samochodu!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Model"
            name="model"
            rules={[{ required: true, message: 'Proszę wpisać model samochodu!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Numer"
            name="number"
            rules={[{ required: true, message: 'Proszę wpisać numer samochodu!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Typ płatności"
            name="paymentType"
            rules={[{ required: true, message: 'Proszę wybrać typ płatności!' }]}
          >
            <Select>
              <Select.Option value="weekly">Tygodniowy</Select.Option>
              <Select.Option value="percentage">Procentowy</Select.Option>
            </Select>
          </Form.Item>

          <Form.Item
            label="Czynsz"
            name="rent"
            rules={[{ required: true, message: 'Proszę wpisać wartość czynszu!' }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label="Komentarze"
            name="comments"
          >
            <Input.TextArea />
          </Form.Item>

          <Form.Item
            label="Kierowca"
            name="driver"
          >
            <AutoComplete
              options={Object.keys(drivers).map(driverId => ({ value: driverId }))}
              placeholder="Wybierz kierowcę"
            />
          </Form.Item>

          <Divider orientation="left" plain>
            Ważne daty auta
          </Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Ważność dowodu"
                name="dowodExpiry"
                rules={[{ required: true, message: 'Proszę wybrać datę ważności dowodu!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ważność przeglądu"
                name="przegladExpiry"
                rules={[{ required: true, message: 'Proszę wybrać datę ważności przeglądu!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Ubezpieczenie (od - do)"
                name="insurance"
                rules={[{ required: true, message: 'Proszę wybrać zakres dat dla ubezpieczenia!' }]}
              >
                <RangePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Wypis TAXI (do kiedy)"
                name="taxiCertificate"
                rules={[{ required: true, message: 'Proszę wybrać datę ważności Wypis TAXI!' }]}
              >
                <DatePicker style={{ width: '100%' }} />
              </Form.Item>
            </Col>
          </Row>

          <Divider orientation="left" plain>
            Skany dokumentów
          </Divider>

          <Row gutter={16}>
            <Col span={12}>
              <Form.Item
                label="Dowód przód"
                name="techPassportFront"
                valuePropName="fileList"
                getValueFromEvent={handleUploadChange('techPassportFront')}
              >
                <Upload
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Wgraj zdjęcie przód dowodu</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Dowód Tył"
                name="techPassportBack"
                valuePropName="fileList"
                getValueFromEvent={handleUploadChange('techPassportBack')}
              >
                <Upload
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Wgraj zdjęcie tył dowodu</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Fotografia Ubezpieczenia"
                name="insurancePhoto"
                valuePropName="fileList"
                getValueFromEvent={handleUploadChange('insurancePhoto')}
              >
                <Upload
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Wgraj zdjęcie ubezpieczenia</Button>
                </Upload>
              </Form.Item>
            </Col>
            <Col span={12}>
              <Form.Item
                label="Fotografia Wypis TAXI"
                name="taxiCertificatePhoto"
                valuePropName="fileList"
                getValueFromEvent={handleUploadChange('taxiCertificatePhoto')}
              >
                <Upload
                  beforeUpload={() => false}
                  listType="picture"
                >
                  <Button icon={<UploadOutlined />}>Wgraj zdjęcie Wypis TAXI</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            <Button type="primary" htmlType="submit" style={{ width: '100%' }}>
              Dodaj Samochód
            </Button>
          </Form.Item>
        </Form>
      </Modal>
      <Modal
        title="Szczegóły Samochodu"
        visible={isDetailsModalOpen}
        onCancel={() => {
          setIsDetailsModalOpen(false);
          setIsEditing(false);
        }}
        footer={[
          !isEditing ? (
            <>
              <Button key="edit" type="primary" onClick={() => setIsEditing(true)}>
                Edytuj
              </Button>
              {selectedCar && selectedCar.driver === 'Brak' && (
                <Button key="delete" danger onClick={confirmDeleteCar}>
                  Usuń samochód
                </Button>
              )}
            </>
          ) : (
            <Button key="save" type="primary" onClick={() => form.submit()}>
              Zapisz
            </Button>
          ),
        ]}
      >
        {selectedCar && (
          <Form
            form={form}
            layout="vertical"
            initialValues={{
              make: selectedCar.make,
              model: selectedCar.model,
              number: selectedCar.number,
              rent: selectedCar.rent,
              status: selectedCar.status,
              dowodExpiry: selectedCar.dowodExpiry,
              przegladExpiry: selectedCar.przegladExpiry,
              insurance: [selectedCar.insurance?.startDate, selectedCar.insurance?.endDate],
              taxiCertificate: selectedCar.taxiCertificate,
              comments: selectedCar.comments,
              driver: selectedCar.driver || 'Brak',
            }}
            onFinish={(values) => handleSaveCar(selectedCar.key, values)}
          >

            <Form.Item label="Marka" name="make">
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item label="Model" name="model">
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item label="Numer" name="number">
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item label="Czynsz" name="rent">
              <Input disabled={!isEditing} />
            </Form.Item>
            <Form.Item label="Status" name="status">
              <Input disabled={true} />
            </Form.Item>
            <Form.Item label="Dowód Ważność" name="dowodExpiry">
              <DatePicker disabled={!isEditing} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Przegląd Ważność" name="przegladExpiry">
              <DatePicker disabled={!isEditing} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Ubezpieczenie (od - do)" name="insurance">
              <RangePicker disabled={!isEditing} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Wypis TAXI Ważność" name="taxiCertificate">
              <DatePicker disabled={!isEditing} style={{ width: '100%' }} />
            </Form.Item>
            <Form.Item label="Komentarze" name="comments">
              <Input.TextArea disabled={!isEditing} />
            </Form.Item>
            <Form.Item label="Kierowca" name="driver">
              <Input.Group compact>
                <AutoComplete
                  style={{ width: 'calc(100% - 100px)' }}
                  disabled={!isEditing}
                  options={Object.keys(drivers).map(driverId => ({ value: driverId }))}
                  placeholder="Wybierz kierowcę"
                  value={form.getFieldValue('driver')}
                  onChange={(value) => form.setFieldsValue({ driver: value })}
                  onSelect={(value) => {
                    form.setFieldsValue({ driver: value });
                    handleDriverChange(selectedCar.key, value);
                  }}
                />
                {isEditing && (
                  <Button
                    danger
                    onClick={() => handleRemoveDriver(selectedCar.key)}
                    style={{ width: '100px' }}
                  >
                    Usuń kierowcę
                  </Button>
                )}
              </Input.Group>
            </Form.Item>

            {selectedCar.techPassportFront && (
              <Button onClick={() => showImageModal(selectedCar.techPassportFront)}>
                Pokaż przód dowodu
              </Button>
            )}
            {selectedCar.techPassportBack && (
              <Button onClick={() => showImageModal(selectedCar.techPassportBack)}>
                Pokaż tył dowodu
              </Button>
            )}
            {selectedCar.insurancePhoto && (
              <Button onClick={() => showImageModal(selectedCar.insurancePhoto)}>
                Pokaż zdjęcie ubezpieczenia
              </Button>
            )}
            {selectedCar.taxiCertificatePhoto && (
              <Button onClick={() => showImageModal(selectedCar.taxiCertificatePhoto)}>
                Pokaż zdjęcie Wypis TAXI
              </Button>
            )}
          </Form>
        )}
      </Modal>

      <Modal
        visible={imageModalVisible}
        footer={null}
        onCancel={() => setImageModalVisible(false)}
      >
        {currentImage && <img alt="Dokument" style={{ width: '100%' }} src={currentImage} />}
      </Modal>

      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          transition: 'opacity 0.5s ease',
        }}>
          <Spin size="large" />
          <div style={{ marginTop: 16, fontSize: 18, color: '#333' }}>
          Przełączam projekt...
                    </div>
        </div>
      )}
    </div>
  );
};

export default Samochody;

import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import { MenuFoldOutlined, MenuUnfoldOutlined, BellOutlined, UserOutlined, UsergroupAddOutlined } from '@ant-design/icons';
import { Button, Layout, theme, Drawer, ConfigProvider, Dropdown, Menu, Select } from 'antd';
import { collection, query, where, onSnapshot } from "firebase/firestore";
import { signOut, onAuthStateChanged } from "firebase/auth";
import { firestore, database, auth } from './firebase';
import { FirebaseProvider, useFirebase } from './FirebaseContext';

import SideMenu from './components/SideMenu';
import Home from './pages/Home';
import Chat from './pages/Chat';
import Rozliczenie from './pages/Rozliczenie';
import Faktury from './pages/Faktury';
import Przelewy from './pages/Przelewy';
import Kierowcy from './pages/Kierowcy';
import Uzytkownicy from './pages/Uzytkownicy';
import Samochody from './pages/Samochody';
import Raport from './pages/Raport';
import FakturyZW from './pages/FakturyZW';
import PIT28 from './pages/PIT28';
import RaportyKsiegowe from './pages/RaportyKsiegowe';
import JPKVAT from './pages/JPKVAT';
import Login from './pages/Login';
import AdminNotificationSender from './pages/AdminNotificationSender';
import Register from './pages/Register';
import Verifikacja from './pages/Verifikacja';
import Wydatki from './pages/Wydatki';
import BonusPartnera from './pages/BonusPartnera';
import logo from './assets/spario.png';
import plPL from 'antd/lib/locale/pl_PL';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.locale('pl');

const { Header, Sider, Content } = Layout;

const AppHeader = ({ collapsed, setCollapsed, isMobile, setDrawerVisible }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const { currentFirebase, currentProject, switchProject } = useFirebase();
  const [pendingUsersCount, setPendingUsersCount] = useState(0);
  const auth = currentFirebase.auth;
  const firestore = currentFirebase.firestore;

  useEffect(() => {
    if (!firestore) return; // Если Firestore ещё не инициализирован
    const q = query(collection(firestore, "users"), where("status", "==", "Oczekuje na weryfikacje"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      console.log("Pending users count (snapshot.size):", snapshot.size);
      snapshot.docs.forEach(doc => {
        console.log("Pending user doc:", doc.id, doc.data());
      });
      setPendingUsersCount(snapshot.size);
    });
    return () => unsubscribe();
  }, [firestore, currentProject]);
  

  const getPageTitle = () => {
    switch (location.pathname) {
      case '/chat': return 'Chat z kierowcami';
      case '/rozliczenie': return 'Rozliczenie';
      case '/faktury': return 'Faktury';
      case '/przelewy': return 'Przelewy';
      case '/kierowcy': return 'Kierowcy';
      case '/uzytkownicy': return 'Użytkownicy';
      case '/samochody': return 'Samochody';
      case '/raport': return 'Raport';
      case '/faktury-zw': return 'Faktury ZW';
      case '/pit-28': return 'PIT 28';
      case '/raporty-ksiegowe': return 'Raporty księgowe';
      case '/jpk-vat': return 'JPK VAT';
      case '/powiadomienia': return 'Powiadomienia';
      case '/verifikacja': return 'Weryfikuj użytkowników';
      case '/wydatki': return 'Wydatki';
      case '/bonus': return 'Bonus Partnera';
      default: return 'Dashboard';
    }
  };

  const handleLogout = async () => {
    await signOut(auth);
    navigate('/login');
  };

  const menu = (
    <Menu>
      <Menu.Item key="logout" onClick={handleLogout}>
        Wyloguj się
      </Menu.Item>
    </Menu>
  );

  return (
    <Header style={{ padding: '0 16px', background: '#fff', display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Button
          type="text"
          icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
          onClick={() => {
            if (isMobile) {
              setDrawerVisible(true);
            } else {
              setCollapsed(!collapsed);
            }
          }}
          style={{ fontSize: '16px', width: 64, height: 64 }}
        />
        <h1 style={{ margin: '0 16px' }}>{getPageTitle()}</h1>
        <Select 
          value={currentProject}
          onChange={switchProject}
          style={{ width: 150, marginLeft: 16 }}
          size="small"
        >
          <Select.Option value="ColorPartner">ColorPartner</Select.Option>
          <Select.Option value="Spario">Spario</Select.Option>
        </Select>
      </div>
      <div>
        <Button
          type="text"
          icon={<UsergroupAddOutlined />}
          style={{ fontSize: '16px', marginRight: '16px' }}
          onClick={() => navigate('/verifikacja')}
        >
          {pendingUsersCount > 0 && <span style={{ marginLeft: 8 }}>{pendingUsersCount}</span>}
        </Button>
        <Button type="text" icon={<BellOutlined />} style={{ fontSize: '16px', marginRight: '16px' }} />
        <Dropdown overlay={menu} placement="bottomRight">
          <Button type="text" icon={<UserOutlined />} style={{ fontSize: '16px' }} />
        </Dropdown>
      </div>
    </Header>
  );
};

const ProtectedRoute = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });
    return () => unsubscribe();
  }, []);
  if (loading) {
    return <div>Loading...</div>;
  }
  return user ? children : <Navigate to="/login" />;
};

const AppLayout = () => {
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [drawerVisible, setDrawerVisible] = useState(false);
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleMenuClick = () => {
    if (isMobile) setDrawerVisible(false);
  };

  return (
    <Layout style={{ minHeight: '100vh' }}>
      {!isMobile ? (
        <Sider trigger={null} collapsible collapsed={collapsed} style={{ height: '100vh', background: '#fff' }}>
          <div style={{ height: 64, display: 'flex', alignItems: 'center', justifyContent: 'center', padding: 16 }}>
            {collapsed ? (
              <img src={logo} alt="Logo" style={{ width: 32, height: 32 }} />
            ) : (
              <img src={logo} alt="Logo" style={{ width: 120, height: 32 }} />
            )}
          </div>
          <SideMenu onClick={handleMenuClick} />
        </Sider>
      ) : (
        <Drawer
          title="Menu"
          placement="left"
          onClose={() => setDrawerVisible(false)}
          visible={drawerVisible}
          bodyStyle={{ padding: 0 }}
          mode="inline"
        >
          <SideMenu onClick={handleMenuClick} />
        </Drawer>
      )}
      <Layout>
        <AppHeader collapsed={collapsed} setCollapsed={setCollapsed} isMobile={isMobile} setDrawerVisible={setDrawerVisible} />
        <Content style={{
          margin: '24px 16px',
          padding: 24,
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
          display: 'flex',
          flexDirection: 'column',
          overflow: 'auto'
        }}>
          <Routes>
            <Route path="/" element={<ProtectedRoute><Home /></ProtectedRoute>} />
            <Route path="/chat" element={<ProtectedRoute><Chat /></ProtectedRoute>} />
            <Route path="/rozliczenie" element={<ProtectedRoute><Rozliczenie /></ProtectedRoute>} />
            <Route path="/faktury" element={<ProtectedRoute><Faktury /></ProtectedRoute>} />
            <Route path="/przelewy" element={<ProtectedRoute><Przelewy /></ProtectedRoute>} />
            <Route path="/kierowcy" element={<ProtectedRoute><Kierowcy /></ProtectedRoute>} />
            <Route path="/uzytkownicy" element={<ProtectedRoute><Uzytkownicy /></ProtectedRoute>} />
            <Route path="/samochody" element={<ProtectedRoute><Samochody /></ProtectedRoute>} />
            <Route path="/raport" element={<ProtectedRoute><Raport /></ProtectedRoute>} />
            <Route path="/faktury-zw" element={<ProtectedRoute><FakturyZW /></ProtectedRoute>} />
            <Route path="/pit-28" element={<ProtectedRoute><PIT28 /></ProtectedRoute>} />
            <Route path="/raporty-ksiegowe" element={<ProtectedRoute><RaportyKsiegowe /></ProtectedRoute>} />
            <Route path="/jpk-vat" element={<ProtectedRoute><JPKVAT /></ProtectedRoute>} />
            <Route path="/powiadomienia" element={<ProtectedRoute><AdminNotificationSender /></ProtectedRoute>} />
            <Route path="/verifikacja" element={<ProtectedRoute><Verifikacja /></ProtectedRoute>} />
            <Route path="/wydatki" element={<ProtectedRoute><Wydatki /></ProtectedRoute>} />
            <Route path="/bonus" element={<ProtectedRoute><BonusPartnera /></ProtectedRoute>} />
          </Routes>
        </Content>
      </Layout>
    </Layout>
  );
};

const App = () => {
  return (
    <ConfigProvider locale={plPL}>
      <FirebaseProvider>
        <Router>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="*" element={<AppLayout />} />
          </Routes>
        </Router>
      </FirebaseProvider>
    </ConfigProvider>
  );
};

export default App;

import React, { useState, useEffect } from 'react';
import { Input, Table, Pagination, Row, Col, DatePicker, message, Button, ConfigProvider, Spin } from 'antd';
import { ref, get, remove } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';
import moment from 'moment';
import 'moment/locale/pl';
import plPL from 'antd/es/locale/pl_PL';

const { RangePicker } = DatePicker;

moment.updateLocale('pl', {
    week: {
        dow: 1,
        doy: 4,
    },
});

const Wydatki = () => {
    // Получаем актуальный экземпляр базы из контекста
    const { currentFirebase } = useFirebase();
    const db = currentFirebase.database;

    const [searchValue, setSearchValue] = useState('');
    const [dateRange, setDateRange] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [rowsPerPage, setRowsPerPage] = useState(15);
    const [wydatekData, setWydatekData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [loading, setLoading] = useState(false);

    // Состояния для оверлея при переключении проекта
    const [isProjectChanging, setIsProjectChanging] = useState(false);
    const [firstLoad, setFirstLoad] = useState(true);

    useEffect(() => {
        loadAndDisplayData();
    }, [db]);

    useEffect(() => {
        filterData();
    }, [searchValue, dateRange, wydatekData]);

    const loadAndDisplayData = async () => {
        setLoading(true);
        const startTime = Date.now();
        // Показываем оверлей только при смене проекта (не при первом рендере)
        if (!firstLoad) {
            setIsProjectChanging(true);
        }
        try {
            const snapshot = await get(ref(db, '/drivers'));
            const drivers = snapshot.val();
            let wydatek = [];

            for (let driverId in drivers) {
                const driverData = drivers[driverId];
                if (driverData.invoices) {
                    for (let invoiceId in driverData.invoices) {
                        const invoice = driverData.invoices[invoiceId];
                        if (invoice.type !== 'wydatek') continue;
                        wydatek.push({
                            ...invoice,
                            driverId,
                            invoiceId,
                            purchaseDate: invoice.purchaseDate, // Формат 'YYYY-MM-DD'
                        });
                    }
                }
            }

            console.log("Data before sorting:", wydatek);
            // Сортировка по дате покупки от новой к старой
            wydatek.sort((a, b) => moment(b.purchaseDate).diff(moment(a.purchaseDate)));
            console.log("Data after sorting:", wydatek);

            setWydatekData(wydatek);
            setFilteredData(wydatek);
            setCurrentPage(1);
        } catch (error) {
            console.error('Error fetching wydatek data: ', error);
            message.error('Błąd podczas pobierania danych.');
        }
        setLoading(false);
        if (!firstLoad) {
            const elapsed = Date.now() - startTime;
            const minimumDisplay = 1000; // минимум 1 секунда
            const delay = Math.max(0, minimumDisplay - elapsed);
            setTimeout(() => {
                setIsProjectChanging(false);
            }, delay);
        } else {
            setFirstLoad(false);
        }
    };

    const filterData = () => {
        const lowerCaseSearchValue = searchValue.toLowerCase();

        let filtered = wydatekData.filter(wydatek => {
            // Фильтрация по всем полям
            const matchesSearch = Object.keys(wydatek).some(key => {
                const value = wydatek[key];
                return value && value.toString().toLowerCase().includes(lowerCaseSearchValue);
            });

            const purchaseDate = wydatek.purchaseDate; // Формат 'YYYY-MM-DD'
            if (!purchaseDate) {
                console.warn(`Invalid date for invoice: ${wydatek.invoiceId}`);
                return false;
            }

            let matchesDateRange = true;
            if (dateRange && dateRange[0] && dateRange[1]) {
                const startDate = dateRange[0].format('YYYY-MM-DD');
                const endDate = dateRange[1].format('YYYY-MM-DD');
                console.log(`Start date: ${startDate}, End date: ${endDate}, Purchase date: ${purchaseDate}`);
                matchesDateRange = purchaseDate >= startDate && purchaseDate <= endDate;
                console.log(`Date matches: ${matchesDateRange}`);
            }

            return matchesSearch && matchesDateRange;
        });

        console.log("Filtered data before sorting:", filtered);
        filtered.sort((a, b) => b.purchaseDate.localeCompare(a.purchaseDate));
        console.log("Filtered data after sorting:", filtered);
        setFilteredData(filtered);
    };

    const handleDelete = async (invoiceId, driverId) => {
        setLoading(true);
        try {
            // Удаление записи из Realtime Database
            await remove(ref(db, `/drivers/${driverId}/invoices/${invoiceId}`));
            // Обновление локального состояния
            setWydatekData(prevData => prevData.filter(wydatek => wydatek.invoiceId !== invoiceId));
            setFilteredData(prevData => prevData.filter(wydatek => wydatek.invoiceId !== invoiceId));
            message.success('Wydatki zostały usunięte.');
        } catch (error) {
            console.error('Error deleting wydatek data: ', error);
            message.error('Błąd podczas usuwania danych.');
        }
        setLoading(false);
    };

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };

    const handleRowsPerPageChange = (current, size) => {
        setRowsPerPage(size);
        setCurrentPage(1);
    };

    const columns = [
        { title: 'Kierowca', dataIndex: 'driverId', key: 'driverId' },
        { title: 'Numer Faktury', dataIndex: 'numerfaktury', key: 'numerfaktury' },
        { title: 'Data zakupu', dataIndex: 'purchaseDate', key: 'purchaseDate' },
        { title: 'Kwota brutto', dataIndex: 'grossAmount', key: 'grossAmount' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        {
            title: 'Akcje',
            key: 'action',
            render: (text, record) => (
                <Button danger onClick={() => handleDelete(record.invoiceId, record.driverId)}>
                    Usuń
                </Button>
            ),
        },
    ];

    const paginatedData = filteredData.slice((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage);

    return (
        <ConfigProvider locale={plPL}>
            <div>
                <Row gutter={16} style={{ marginBottom: '16px', alignItems: 'center', width: '100%' }}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                        <Input
                            id="search"
                            placeholder="Wyszukaj numer faktury"
                            value={searchValue}
                            onChange={(e) => setSearchValue(e.target.value)}
                            style={{ flex: '7', marginRight: '16px' }}
                        />
                        <RangePicker
                            id="date-range"
                            style={{ flex: '3' }}
                            value={dateRange}
                            onChange={(dates) => setDateRange(dates || null)}
                            format="YYYY-MM-DD"
                        />
                    </Col>
                </Row>

                <Table
                    id="data-table"
                    columns={columns}
                    dataSource={paginatedData}
                    pagination={false}
                    rowKey="invoiceId"
                    loading={loading}
                />

                <Pagination
                    id="pagination"
                    style={{ textAlign: 'right', marginTop: '16px' }}
                    current={currentPage}
                    total={filteredData.length}
                    pageSize={rowsPerPage}
                    onChange={handlePageChange}
                    pageSizeOptions={['10', '15', '20', '25']}
                    onShowSizeChange={handleRowsPerPageChange}
                    showSizeChanger
                />

                {isProjectChanging && (
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(255,255,255,0.8)',
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        zIndex: 1000,
                        transition: 'opacity 0.5s ease',
                    }}>
                        <Spin size="large" />
                        <div style={{ marginTop: 16, fontSize: 18, color: '#333' }}>
                            Przełączam projekt...
                        </div>
                    </div>
                )}
            </div>
        </ConfigProvider>
    );
};

export default Wydatki;

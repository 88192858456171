import React, { useState, useEffect } from 'react';
import { 
  Card, Col, Row, Form, Input, Button, DatePicker, Select, message, Upload, AutoComplete, Divider, Spin 
} from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ref, onValue, push } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';
import NegativeBalanceDrivers from '../components/NegativeBalanceDrivers';
import OpenChats from '../components/OpenChats';
import moment from 'moment';
import 'moment/locale/pl';
import dayjs from 'dayjs';
import isoWeek from 'dayjs/plugin/isoWeek';
import 'dayjs/locale/pl';

dayjs.extend(isoWeek);
dayjs.locale('pl');
moment.updateLocale('pl', {
  week: {
    dow: 1,
  },
});

const { Option } = Select;
const { RangePicker } = DatePicker;

const rangePresets = [
  { label: 'Ostatnie 7 dni', value: [dayjs().subtract(7, 'day'), dayjs()] },
  { label: 'Ostatnie 14 dni', value: [dayjs().subtract(14, 'day'), dayjs()] },
  { label: 'Ostatnie 30 dni', value: [dayjs().subtract(30, 'day'), dayjs()] },
];

const Home = () => {
  // Получаем текущий экземпляр Firebase и конфигурацию проекта из контекста
  const { currentFirebase, currentProject } = useFirebase();
  const db = currentFirebase.database;
  const { config } = currentFirebase; // Здесь хранится firebaseConfig для текущего проекта

  const [drivers, setDrivers] = useState([]);
  const [options, setOptions] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [expenseForm] = Form.useForm();
  const [bonusForm] = Form.useForm();

  // Флаг для отображения оверлея при смене проекта
  const [isProjectChanging, setIsProjectChanging] = useState(false);

  const onRangeChange = (dates, dateStrings) => {
    if (dates) {
      console.log('From: ', dates[0], ', to: ', dates[1]);
      console.log('From: ', dateStrings[0], ', to: ', dateStrings[1]);
    } else {
      console.log('Clear');
    }
  };

  useEffect(() => {
    // При изменении базы выставляем флаг и загружаем kierowców
    setIsProjectChanging(true);
    const driversRef = ref(db, 'drivers');
    const unsubscribe = onValue(
      driversRef,
      (snapshot) => {
        const driversList = [];
        snapshot.forEach((childSnapshot) => {
          driversList.push({ value: childSnapshot.key, label: childSnapshot.key });
        });
        setDrivers(driversList);
        setIsProjectChanging(false);
      },
      (error) => {
        console.error('Error fetching drivers:', error);
        message.error('Nie udało się załadować listy kierowców.');
        setIsProjectChanging(false);
      }
    );
    return () => unsubscribe();
  }, [db]);

  const handleSearch = (value) => {
    setOptions(
      !value
        ? []
        : drivers.filter((driver) =>
            driver.label.toLowerCase().includes(value.toLowerCase())
          )
    );
  };

  const handleExpenseSubmit = (values) => {
    const { driver, expenseType, grossAmount, purchaseDate } = values;
    if (!driver || !expenseType || !grossAmount || !purchaseDate) {
      message.error('Proszę wypełnić wszystkie pola.');
      return;
    }
    const expenseData = {
      type: 'wydatek',
      numerfaktury: expenseType,
      grossAmount,
      purchaseDate: purchaseDate.format('YYYY-MM-DD'),
      status: 'zaakceptowany',
    };
    const driverRef = ref(db, `drivers/${driver}/invoices`);
    push(driverRef, expenseData)
      .then(() => {
        message.success('Wydatek dodany.');
        expenseForm.resetFields();
      })
      .catch((error) => {
        console.error('Error adding expense:', error);
        message.error('Nie udało się dodać wydatku.');
      });
  };

  const handleBonusSubmit = (values) => {
    const { bonusDriver, bonusReason, bonusAmount, bonusDate, bonusComment } = values;
    if (!bonusDriver || !bonusReason || !bonusAmount || !bonusDate) {
      message.error('Proszę wypełnić wszystkie pola.');
      return;
    }
    const bonusData = {
      reason: bonusReason,
      amount: bonusAmount,
      date: bonusDate.format('YYYY-MM-DD'),
      comment: bonusComment || '',
    };
    const bonusRef = ref(db, `drivers/${bonusDriver}/bonus_partnera`);
    push(bonusRef, bonusData)
      .then(() => {
        message.success('Bonus partnera dodany.');
        bonusForm.resetFields();
      })
      .catch((error) => {
        console.error('Error adding bonus:', error);
        message.error('Nie udało się dodać bonusu.');
      });
  };

  const handleUploadSubmit = async (values) => {
    const { fileType, city, dateRange } = values;
    const [startDate, endDate] = dateRange;
    const weekNumber = getWeekNumber(new Date(startDate));

    if (fileList.length === 0) {
      message.error('Proszę wybrać plik.');
      return;
    }

    const file = fileList[0].originFileObj;
    const fileContentBase64 = await toBase64(file);

    console.log('Wysyłanie danych:');
    console.log('Typ pliku:', fileType);
    console.log('Miasto:', city);
    console.log('Tydzień:', weekNumber);
    console.log('Data rozpoczęcia:', startDate.format('YYYY-MM-DD'));
    console.log('Data zakończenia:', endDate.format('YYYY-MM-DD'));
    console.log('Plik:', file);

    message.loading('Wysyłanie danych na serwer...', 0);

    // Формирование URL в зависимости от выбранного проекта (projectId берется из конфигурации)
    const uploadUrl = `https://us-central1-${config.projectId}.cloudfunctions.net/uploadCalculaet/upload`;

    fetch(uploadUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        fileType,
        week: weekNumber,
        csvfile: fileContentBase64,
        city,
        startDate: startDate.format('YYYY-MM-DD'),
        endDate: endDate.format('YYYY-MM-DD')
      }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error('Sieć odpowiedziała niepoprawnie');
        }
        return response.json();
      })
      .then(data => {
        console.log(data);
        message.destroy();
        message.success('Dane przetworzone pomyślnie!');
        setFileList([]);
      })
      .catch(error => {
        console.error('Błąd:', error);
        message.destroy();
        message.error('Błąd przetwarzania danych. Spróbuj ponownie.');
      });
  };

  const toBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result.split(',')[1]);
      reader.onerror = error => reject(error);
    });
  };

  const getWeekNumber = (d) => {
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    return weekNo + '-' + d.getUTCFullYear();
  };

  return (
    <>
      <Row gutter={16}>
        <Divider orientation="left" plain>
          Rozliczenia kierowców
        </Divider>
        <Col span={8}>
          <Card title="Dodaj wydatek" bordered={false}>
            <Form form={expenseForm} layout="vertical" onFinish={handleExpenseSubmit}>
              <Form.Item
                label="Kierowca"
                name="driver"
                rules={[{ required: true, message: 'Proszę wybrać kierowcę' }]}
              >
                <AutoComplete
                  options={options}
                  onSearch={handleSearch}
                  placeholder="Wybierz kierowcę"
                  filterOption={(inputValue, option) =>
                    option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                  }
                />
              </Form.Item>
              <Form.Item
                label="Typ wydatku"
                name="expenseType"
                rules={[{ required: true, message: 'Proszę podać typ wydatku' }]}
              >
                <Input placeholder="Podaj typ wydatku" />
              </Form.Item>
              <Form.Item
                label="Kwota wydatków"
                name="grossAmount"
                rules={[{ required: true, message: 'Proszę podać kwotę wydatków' }]}
              >
                <Input type="number" step="0.01" placeholder="Podaj kwotę wydatków" />
              </Form.Item>
              <Form.Item
                label="Data transakcji"
                name="purchaseDate"
                rules={[{ required: true, message: 'Proszę wybrać datę transakcji' }]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} placeholder="Wybierz datę" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Dodaj wydatek
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Dodaj bonus partnera" bordered={false}>
            <Form form={bonusForm} layout="vertical" onFinish={handleBonusSubmit}>
              <Row gutter={16}>
                <Col span={12}>
                  <Form.Item
                    label="Kierowca"
                    name="bonusDriver"
                    rules={[{ required: true, message: 'Proszę wybrać kierowcę' }]}
                  >
                    <AutoComplete
                      options={options}
                      onSearch={handleSearch}
                      placeholder="Wybierz kierowcę"
                      filterOption={(inputValue, option) =>
                        option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
                      }
                    />
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item
                    label="Powód"
                    name="bonusReason"
                    rules={[{ required: true, message: 'Proszę podać powód' }]}
                  >
                    <Input placeholder="Podaj powód" />
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                label="Kwota"
                name="bonusAmount"
                rules={[{ required: true, message: 'Proszę podać kwotę' }]}
              >
                <Input type="number" step="0.01" placeholder="Podaj kwotę" />
              </Form.Item>
              <Form.Item label="Komentarz" name="bonusComment">
                <Input placeholder="Podaj komentarz" />
              </Form.Item>
              <Form.Item
                label="Data rozliczenia"
                name="bonusDate"
                rules={[{ required: true, message: 'Proszę wybrać datę rozliczenia' }]}
              >
                <DatePicker format="YYYY-MM-DD" style={{ width: '100%' }} placeholder="Wybierz datę" />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Dodaj bonus partnera
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Card title="Rozlicz kierowców" bordered={false}>
            <Form layout="vertical" onFinish={handleUploadSubmit}>
              <Form.Item
                label="Rodzaj pliku"
                name="fileType"
                rules={[{ required: true, message: 'Proszę wybrać rodzaj pliku' }]}
              >
                <Select style={{ width: '100%' }} placeholder="Wybierz rodzaj pliku">
                  <Option value="uber">Uber</Option>
                  <Option value="bolt">Bolt</Option>
                  <Option value="freenow">FreeNow</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Miasto"
                name="city"
                rules={[{ required: true, message: 'Proszę wybrać miasto' }]}
              >
                <Select style={{ width: '100%' }} placeholder="Wybierz miasto">
                  <Option value="warszawa">Warszawa</Option>
                  <Option value="krakow">Krakow</Option>
                  <Option value="wroclaw">Wroclaw</Option>
                  <Option value="poznan">Poznan</Option>
                  <Option value="gdansk">Gdansk</Option>
                  <Option value="szczecin">Szczecin</Option>
                  <Option value="lodz">Lodz</Option>
                  <Option value="lublin">Lublin</Option>
                  <Option value="katowice">Katowice</Option>
                  <Option value="bialystok">Bialystok</Option>
                  <Option value="gdynia">Gdynia</Option>
                  <Option value="rzeszow">Rzeszów</Option>
                  <Option value="kalisz">Kalisz</Option>
                  <Option value="kielce">Kielce</Option>
                  <Option value="sopot">Sopot</Option>
                  <Option value="trojmiasto">Trójmiasto</Option>
                  <Option value="opole">Opole</Option>
                  <Option value="gorzow_wielkopolski">Gorzów Wielkopolski</Option>
                  <Option value="bielsko_biala">Bielsko Biała</Option>
                  <Option value="olsztyn">Olsztyn</Option>
                  <Option value="slupsk">Słupsk</Option>
                </Select>
              </Form.Item>
              <Form.Item
                label="Wybierz zakres dat"
                name="dateRange"
                rules={[{ required: true, message: 'Proszę wybrać zakres dat' }]}
              >
                <RangePicker
                  presets={rangePresets}
                  format="YYYY-MM-DD"
                  style={{ width: '100%' }}
                  placeholder={["Początek", "Koniec"]}
                  onChange={onRangeChange}
                />
              </Form.Item>
              <Form.Item
                label="Wybierz plik"
                name="csvfile"
                rules={[{ required: true, message: 'Proszę wybrać plik' }]}
              >
                <Upload
                  beforeUpload={() => false}
                  fileList={fileList}
                  onChange={({ fileList }) => setFileList(fileList)}
                  style={{ width: '100%' }}
                >
                  <Button icon={<UploadOutlined />} style={{ width: '100%' }}>
                    Kliknij, aby wybrać plik
                  </Button>
                </Upload>
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  Rozlicz kierowców
                </Button>
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Divider orientation="left" plain>
          Salda kierowców & Chat
        </Divider>
        <Col span={12}>
          <NegativeBalanceDrivers />
        </Col>
        <Col span={12}>
          <OpenChats />
        </Col>
      </Row>
      {/* Оверлей для уведомления о смене проекта */}
      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin tip="Przełączam projekt..." size="large" />
        </div>
      )}
    </>
  );
};

export default Home;

import React, { useEffect, useState } from 'react';
import { collection, doc, getDoc, query, where, getDocs, updateDoc } from "firebase/firestore";
import { Table, Button, Modal, Collapse, Spin } from 'antd';
import { useFirebase } from '../FirebaseContext';

const { Panel } = Collapse;

const Verifikacja = () => {
  const { currentFirebase } = useFirebase();
  const firestoreInstance = currentFirebase.firestore;

  const [users, setUsers] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [modalData, setModalData] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageModal, setImageModal] = useState({ visible: false, src: '' });

  // Состояния для оверлея при переключении проекта
  const [isProjectChanging, setIsProjectChanging] = useState(false);
  const [firstLoad, setFirstLoad] = useState(true);

  useEffect(() => {
    const fetchUsers = async () => {
      const startTime = Date.now();
      if (!firstLoad) {
        setIsProjectChanging(true);
      }
      const q = query(
        collection(firestoreInstance, "users"),
        where("status", "==", "Oczekuje na weryfikacje")
      );
      const querySnapshot = await getDocs(q);
      setUsers(querySnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() })));

      if (!firstLoad) {
        const elapsed = Date.now() - startTime;
        const minimumDisplay = 1000; // 1 секунда
        const delay = Math.max(0, minimumDisplay - elapsed);
        setTimeout(() => {
          setIsProjectChanging(false);
        }, delay);
      } else {
        setFirstLoad(false);
      }
    };

    fetchUsers();
  }, [firestoreInstance, firstLoad]);

  const openModal = async (userId) => {
    setSelectedUserId(userId);
    setIsModalOpen(true);

    const basicData = await loadBasicUserData(userId);
    const danePersonalne = await loadDanePersonalne(userId);
    const samochod = await loadSamochod(userId);
    const podatki = await loadPodatki(userId);
    const daneDodatkowe = await loadDaneDodatkowe(userId);

    setModalData({
      basicData,
      danePersonalne,
      samochod,
      podatki,
      daneDodatkowe,
    });
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setModalData({});
  };

  const openImageModal = (src) => {
    setImageModal({ visible: true, src });
  };

  const closeImageModal = () => {
    setImageModal({ visible: false, src: '' });
  };

  const loadBasicUserData = async (userId) => {
    const docRef = doc(firestoreInstance, 'users', userId);
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  };

  const loadDanePersonalne = async (userId) => {
    const docRef = doc(firestoreInstance, 'users', userId, 'dokumenty', 'dane_personalne');
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  };

  const loadSamochod = async (userId) => {
    const docRef = doc(firestoreInstance, 'users', userId, 'dokumenty', 'samochod');
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  };

  const loadPodatki = async (userId) => {
    const docRef = doc(firestoreInstance, 'users', userId, 'dokumenty', 'dane_podatkowe');
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  };

  const loadDaneDodatkowe = async (userId) => {
    const docRef = doc(firestoreInstance, 'users', userId, 'dokumenty', 'dane_dodatkowe');
    const docSnap = await getDoc(docRef);
    return docSnap.exists() ? docSnap.data() : {};
  };

  const approveUser = async (userId) => {
    await updateDoc(doc(firestoreInstance, 'users', userId), {
      status: 'Wymagana weryfikcja',
    });
    alert('Użytkownik został zweryfikowany.');
    closeModal();
    const q = query(
      collection(firestoreInstance, "users"),
      where("status", "==", "Oczekuje na weryfikacje")
    );
    const querySnapshot = await getDocs(q);
    setUsers(querySnapshot.docs.map(docSnap => ({ id: docSnap.id, ...docSnap.data() })));
  };

  const columns = [
    {
      title: 'ID Kierowcy',
      dataIndex: 'driverId',
      key: 'driverId',
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Imię',
      dataIndex: 'firstName',
      key: 'firstName',
    },
    {
      title: 'Nazwisko',
      dataIndex: 'lastName',
      key: 'lastName',
    },
    {
      title: 'Telefon',
      dataIndex: 'phoneNumber',
      key: 'phone',
    },
    {
      title: 'Rola',
      dataIndex: 'role',
      key: 'role',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Weryfikacja',
      key: 'action',
      render: (text, record) => (
        <Button type="primary" onClick={() => openModal(record.id)}>
          Weryfikacja
        </Button>
      ),
    },
  ];

  return (
    <div>
      <h2>Użytkownicy oczekujący na weryfikację</h2>
      <Table
        columns={columns}
        dataSource={users}
        rowKey={record => record.id}
      />

      <Modal
        title="Weryfikacja Użytkownika"
        visible={isModalOpen}
        onCancel={closeModal}
        footer={[
          <Button key="cancel" onClick={closeModal}>
            Zamknij
          </Button>,
          <Button key="verify" type="primary" onClick={() => approveUser(selectedUserId)}>
            Weryfikuj
          </Button>,
        ]}
        width={800}
      >
        <Collapse accordion>
          <Panel header="Podstawowe dane użytkownika" key="1">
            <p>Obywatelstwo: {modalData.basicData?.citizenship || 'Brak danych'}</p>
            <p>Kraj: {modalData.basicData?.country || 'Brak danych'}</p>
            <p>ID Kierowcy: {modalData.basicData?.driverId || 'Brak danych'}</p>
            <p>Email: {modalData.basicData?.email || 'Brak danych'}</p>
            <p>Imię: {modalData.basicData?.firstName || 'Brak danych'}</p>
            <p>Nazwisko: {modalData.basicData?.lastName || 'Brak danych'}</p>
            <p>Okres rozliczeniowy: {modalData.basicData?.okresrozliczeniowy || 'Brak danych'}</p>
            <p>Telefon: {modalData.basicData?.phoneNumber || 'Brak danych'}</p>
            <p>Data rejestracji: {modalData.basicData?.registeredAt ? new Date(modalData.basicData.registeredAt.seconds * 1000).toLocaleString() : 'Brak danych'}</p>
            <p>Rola: {modalData.basicData?.role || 'Brak danych'}</p>
            <p>Status: {modalData.basicData?.status || 'Brak danych'}</p>
            <p>ID Sumsub: {modalData.basicData?.sumsubApplicantId || 'Brak danych'}</p>
          </Panel>
          <Panel header="Dane Personalne" key="2">
            <p>Obywatelstwo: {modalData.danePersonalne?.citizenship || 'Brak danych'}</p>
            <p>Miasto: {modalData.danePersonalne?.city || 'Brak danych'}</p>
            <p>Adres korespondencyjny: {modalData.danePersonalne?.correspondenceAddress || 'Brak danych'}</p>
            <p>Data urodzenia: {modalData.danePersonalne?.dob || 'Brak danych'}</p>
            <p>PESEL: {modalData.danePersonalne?.pesel || 'Brak danych'}</p>
            <p>Kod pocztowy: {modalData.danePersonalne?.postalCode || 'Brak danych'}</p>
            <p>Adres rejestracji: {modalData.danePersonalne?.registrationAddress || 'Brak danych'}</p>
            <p>Adres zamieszkania: {modalData.danePersonalne?.residenceAddress || 'Brak danych'}</p>
            <p>Ulica: {modalData.danePersonalne?.street || 'Brak danych'}</p>
          </Panel>
          <Panel header="Samochod" key="3">
            <p>Producent: {modalData.samochod?.carMake || 'Brak danych'}</p>
            <p>Model: {modalData.samochod?.carModel || 'Brak danych'}</p>
            <p>Jakim autem: {modalData.samochod?.carOwnership || 'Brak danych'}</p>
            <p>Tablice rejestracyjne: {modalData.samochod?.carRegistrationNumber || 'Brak danych'}</p>
            <p>VIN: {modalData.samochod?.carVin || 'Brak danych'}</p>
            <p>Rok: {modalData.samochod?.carYear || 'Brak danych'}</p>
            <p>ID Dowodu Tył:
              <Button onClick={() => openImageModal(modalData.samochod?.dowodPrzod)}>
                Zobacz dokument
              </Button>
            </p>
            <p>ID Dowodu Przód:
              <Button onClick={() => openImageModal(modalData.samochod?.dowodTyl)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Ubezpieczenie:
              <Button onClick={() => openImageModal(modalData.samochod?.ubezpieczenie)}>
                Zobacz dokument
              </Button>
            </p>
          </Panel>
          <Panel header="Dane Podatkowe" key="4">
            <p>Urząd Skarbowy: {modalData.podatki?.urzadSkarbowy || 'Brak danych'}</p>
            <p>Rachunek Bankowy: {modalData.podatki?.rachunekBankowy || 'Brak danych'}</p>
          </Panel>
          <Panel header="Dane Dodatkowe" key="5">
            <p>Badania Lekarskie: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.badaniaLekarskie)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Badania Psychologiczne: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.badaniaPsychologiczne)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Dowód Osobisty Przód: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.dowodOsobistyPrzod)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Dowód Osobisty Tył: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.dowodOsobistyTyl)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Niekaralność: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.niekaralnosc)}>
                Zobacz dokument
              </Button>
            </p>
            <p>Zdjęcie Profilowe: 
              <Button onClick={() => openImageModal(modalData.daneDodatkowe?.zdjecieProfilowe)}>
                Zobacz dokument
              </Button>
            </p>
          </Panel>
        </Collapse>
      </Modal>

      <Modal
        visible={imageModal.visible}
        footer={[
          <Button key="download" href={imageModal.src} target="_blank" rel="noopener noreferrer">
            Pobierz
          </Button>,
          <Button key="close" onClick={closeImageModal}>
            Zamknij
          </Button>,
        ]}
        onCancel={closeImageModal}
      >
        <img src={imageModal.src} alt="Dokument" style={{ width: '100%' }} />
      </Modal>

      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
          transition: 'opacity 0.5s ease',
        }}>
          <Spin size="large" />
          <div style={{ marginTop: 16, fontSize: 18, color: '#333' }}>
            Przełączam projekt...
          </div>
        </div>
      )}
    </div>
  );
};

export default Verifikacja;

import React, { useState, useEffect } from 'react';
import { Card, Button, Modal, Input, Spin, message } from 'antd';
import { ref, onValue, update, get } from 'firebase/database';
import { useFirebase } from '../FirebaseContext';

const NegativeBalanceDrivers = () => {
  // Получаем текущий экземпляр Firebase через контекст
  const { currentFirebase } = useFirebase();
  const db = currentFirebase.database;

  const [drivers, setDrivers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isProjectChanging, setIsProjectChanging] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedDriver, setSelectedDriver] = useState(null);
  const [amount, setAmount] = useState('');
  const [allDriversModalVisible, setAllDriversModalVisible] = useState(false);

  useEffect(() => {
    // При изменении базы (то есть currentFirebase) обновляем данные и показываем оверлей
    setIsProjectChanging(true);
    const driversRef = ref(db, 'drivers');
    setLoading(true);
    const unsubscribe = onValue(
      driversRef,
      (snapshot) => {
        const driversList = [];
        snapshot.forEach((childSnapshot) => {
          const driverData = childSnapshot.val();
          const driverBalance = Number(driverData['balance']);
          if (driverBalance < 0) {
            driversList.push({ id: childSnapshot.key, balance: driverBalance });
          }
        });
        setDrivers(driversList);
        setLoading(false);
        setIsProjectChanging(false);
      },
      (error) => {
        console.error('Error fetching drivers:', error);
        message.error('Nie udało się załadować listy kierowców.');
        setLoading(false);
        setIsProjectChanging(false);
      }
    );
    return () => unsubscribe();
  }, [db]);

  const handleDeposit = (driverID) => {
    setSelectedDriver(driverID);
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    if (amount && Number(amount) > 0) {
      const driverRef = ref(db, `drivers/${selectedDriver}`);
      try {
        const snapshot = await get(driverRef);
        if (snapshot.exists()) {
          const currentBalance = Number(snapshot.val().balance);
          const newBalance = currentBalance + Number(amount);

          await update(driverRef, { balance: newBalance });

          if (newBalance >= 0) {
            setDrivers(drivers.filter(driver => driver.id !== selectedDriver));
          } else {
            setDrivers(drivers.map(driver =>
              driver.id === selectedDriver ? { ...driver, balance: newBalance } : driver
            ));
          }
          setIsModalVisible(false);
          setAmount('');
        }
      } catch (error) {
        console.error('Error updating balance:', error);
      }
    } else {
      alert('Proszę wpisać poprawną kwotę.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setAmount('');
  };

  const topDrivers = drivers
    .sort((a, b) => a.balance - b.balance)
    .slice(0, 5);

  return (
    <>
      <Card
        title={`Kierowcy z ujemnym saldem (${drivers.length})`}
        extra={<Button type="link" onClick={() => setAllDriversModalVisible(true)}>Zobacz wszystkich</Button>}
      >
        {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '150px' }}>
            <Spin size="large" />
          </div>
        ) : (
          topDrivers.length > 0 ? (
            topDrivers.map(driver => (
              <div key={driver.id} className="driver-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <span>Kierowca: {driver.id}</span>
                <div style={{ display: 'flex', gap: '10px' }}>
                  <span>Dług: {driver.balance.toFixed(2)} zł</span>
                  <Button type="primary" onClick={() => handleDeposit(driver.id)}>Wpłać</Button>
                </div>
              </div>
            ))
          ) : (
            <p>Nie znaleziono kierowców z ujemnym saldem.</p>
          )
        )}
      </Card>

      <Modal
        title="Wpłata"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Input
          type="number"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          placeholder="Wprowadź kwotę"
        />
      </Modal>

      <Modal
        title="Wszyscy kierowcy z ujemnym saldem"
        visible={allDriversModalVisible}
        onCancel={() => setAllDriversModalVisible(false)}
        footer={null}
      >
        {drivers.length > 0 ? (
          drivers.map(driver => (
            <div key={driver.id} className="driver-item" style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
              <span>Kierowca: {driver.id}</span>
              <div style={{ display: 'flex', gap: '10px' }}>
                <span>Dług: {driver.balance.toFixed(2)} zł</span>
                <Button type="primary" onClick={() => handleDeposit(driver.id)}>Wpłać</Button>
              </div>
            </div>
          ))
        ) : (
          <p>Nie znaleziono kierowców z ujemnym saldem.</p>
        )}
      </Modal>

      {/* Оверлей уведомления о сменie проекта */}
      {isProjectChanging && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(255,255,255,0.8)',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          zIndex: 1000,
        }}>
          <Spin tip="Przełączam projekt..." size="large" />
        </div>
      )}
    </>
  );
};

export default NegativeBalanceDrivers;
